import { brandGray700, brandGray900, theme } from '@config/theme'
import { Purchaseoption } from '@libs/types/Purchaseoption'
import styled, { ThemeProvider } from 'styled-components'

export interface PurchaseOptionsProps {
  purchase_options_decorated: Purchaseoption[]
}

const renderPurchaseOptionText = (purchase_options_decorated: Purchaseoption[]): string => {
  if (!purchase_options_decorated || purchase_options_decorated.length === 0) {
    return 'Unknown'
  }
  if (purchase_options_decorated.length === 1) {
    return `${purchase_options_decorated[0].name}`
  }
  if (purchase_options_decorated.length === 2) {
    return `${purchase_options_decorated[0].name} or ${purchase_options_decorated[1].name}`
  }

  return `${purchase_options_decorated
    .slice(0, purchase_options_decorated.length - 1)
    .map((item) => item.name)
    .join(', ')} or ${purchase_options_decorated[purchase_options_decorated.length - 1].name}`
}
export const PurchaseOptions = ({ purchase_options_decorated }: PurchaseOptionsProps) => {
  return (
    <ThemeProvider theme={theme}>
      <PurchaseOptionTitleText>Purchase options</PurchaseOptionTitleText>
      <PurchaseOptionText data-testid="purchase_options-value">
        {renderPurchaseOptionText(purchase_options_decorated)}
      </PurchaseOptionText>
    </ThemeProvider>
  )
}

const PurchaseOptionTitleText = styled.p`
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: ${brandGray900};
`

const PurchaseOptionText = styled.span`
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  color: ${brandGray700};
`
