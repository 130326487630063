import { Link } from 'react-router-dom'
import styled from 'styled-components'

import logoSrc from './logo.svg'

interface LogoProps {
  alt?: string
}

const LogoLink = styled(Link)`
  align-self: center;
`

const LogoImage = styled.img`
  // margin-left: 12px;
  // margin-right: 12px;
`

/**
 * Primary UI component for user interaction
 */
export const Logo = ({ alt = 'Camperoni' }: LogoProps) => (
  <LogoLink to={'/'}>
    <LogoImage src={logoSrc} width={155} height={52} alt={alt} />
  </LogoLink>
)
