import { Icons } from '@assets/svg'
import { colors, theme } from '@config/theme'
import { timeFormatter } from '@decorators/number-formatter.ts'
import { Camp } from '@libs/types/Camp.ts'
import { Box } from '@mui/material'
import styled, { ThemeProvider } from 'styled-components'

export const CampTimes = ({
  start_time,
  end_time,
  earliest_dropoff_time,
  latest_pickup_time,
  extended_earliest_dropoff_time,
  extended_latest_pickup_time,
  day_length,
}: Partial<Camp>) => {
  const formatTime = (time: string | null | undefined) => (time ? timeFormatter(time) : 'Unknown')
  const isOvernight = day_length === 'OVERNIGHT'

  const startTime = formatTime(start_time)
  const endTime = formatTime(end_time)

  let mainTime = startTime === 'Unknown' && endTime === 'Unknown' ? `Unknown` : `${startTime} - ${endTime}`

  if (isOvernight) {
    start_time = end_time = null
  }
  if (!isOvernight && start_time && end_time && start_time > end_time) {
    mainTime = 'Unknown'
  }

  if (earliest_dropoff_time && start_time && earliest_dropoff_time > start_time) {
    earliest_dropoff_time = null
  }
  if (
    (extended_earliest_dropoff_time &&
      earliest_dropoff_time &&
      extended_earliest_dropoff_time > earliest_dropoff_time) ||
    (extended_latest_pickup_time && start_time && extended_latest_pickup_time > start_time)
  ) {
    extended_earliest_dropoff_time = null
  }

  if (latest_pickup_time && end_time && latest_pickup_time < end_time) {
    latest_pickup_time = null
  }

  if (
    (extended_latest_pickup_time && latest_pickup_time && extended_latest_pickup_time < latest_pickup_time) ||
    (extended_latest_pickup_time && end_time && extended_latest_pickup_time < end_time)
  ) {
    latest_pickup_time = null
  }

  const earliestDropoffTime = earliest_dropoff_time ? timeFormatter(earliest_dropoff_time) : '---'
  const extendedEarliestDropoffTime = extended_earliest_dropoff_time
    ? timeFormatter(extended_earliest_dropoff_time)
    : '---'

  const latestPickupEnds = latest_pickup_time ? timeFormatter(latest_pickup_time) : '---'
  const extendedLatestPickupTime = extended_latest_pickup_time ? timeFormatter(extended_latest_pickup_time) : '---'

  const beforeCare =
    extendedEarliestDropoffTime === '---' || startTime === 'Unknown' || extendedEarliestDropoffTime > startTime
      ? '---'
      : `${extendedEarliestDropoffTime} - ${startTime}`
  const afterCare =
    extendedLatestPickupTime === '---' || endTime === 'Unknown' ? '---' : `${endTime} - ${extendedLatestPickupTime}`

  const careTimes = [
    { testId: 'dropoff_starts', label: 'Drop off starts', time: earliestDropoffTime },
    { testId: 'pickup_ends', label: 'Pickup ends', time: latestPickupEnds },
    { testId: 'before_care', label: 'Before care', time: beforeCare },
    { testId: 'after_care', label: 'After care', time: afterCare },
  ]
  return (
    <ThemeProvider theme={theme}>
      <CampTimesWrapper>
        <TimeSection>
          <TimeIcon>
            <Icons.ClockIcon />
          </TimeIcon>
          <MainTime data-testid={'start_end_time-value'}>{mainTime}</MainTime>
        </TimeSection>
        <CareTimes>
          {careTimes.map((careTime, index) => (
            <Box key={index} sx={{ display: 'flex' }}>
              <CareLabel>{careTime.label}</CareLabel>
              <CareTime data-testid={`${careTime.testId}-value`}>{careTime.time}</CareTime>
            </Box>
          ))}
        </CareTimes>
      </CampTimesWrapper>
    </ThemeProvider>
  )
}

// Styled Components
const CampTimesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  @media ${({ theme }) => theme.device['md']} {
    margin-left: 0.5rem;
  }
`

const TimeSection = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: 10px;
`

const TimeIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`

const MainTime = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: ${colors.brandGray700};
`

const CareTimes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 1rem;
  color: ${colors.brandGray700};
`

const CareLabel = styled.div`
  font-weight: 600;
  text-align: left;
  flex: 1;
  color: ${colors.brandGray900};
`

const CareTime = styled.div`
  font-weight: 400;
  text-align: right;
`
