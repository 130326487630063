import { Icons } from '@assets/svg'
import { colors, theme } from '@config/theme'
import { ClickAwayListener, tooltipClasses, TooltipProps, Typography, useMediaQuery } from '@mui/material'
import { Box, Tooltip } from '@mui/material'
import React from 'react'
import styled, { ThemeProvider } from 'styled-components'

type DayLengthProps = {
  day_length?: 'FULL' | 'HALF_AM' | 'HALF_PM' | 'OVERNIGHT' | null
  half_day_combinable?: boolean
}

export const DayLength: React.FC<DayLengthProps> = ({ day_length, half_day_combinable }) => {
  const isLargeScreen: boolean = useMediaQuery(theme.device['md'])

  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const handleTooltipToggle = () => {
    setOpen((prev) => !prev)
  }

  const getDayLengthInfo = () => {
    switch (day_length) {
      case 'FULL':
        return {
          dayLengthIcon: (
            <Icons.FullSun dataTestid="day_length_icon" dataStatus="full-sun" size={isLargeScreen ? 40 : 20} />
          ),
          dayLength: 'Full day',
        }
      case 'HALF_AM':
        return {
          dayLengthIcon: (
            <Icons.HalfSunLeft dataTestid="day_length_icon" dataStatus="left-sun" size={isLargeScreen ? 16 : 10} />
          ),
          dayLength: '½ day AM',
        }
      case 'HALF_PM':
        return {
          dayLengthIcon: (
            <Icons.HalfSunRight dataTestid="day_length_icon" dataStatus="right-sun" size={isLargeScreen ? 16 : 10} />
          ),
          dayLength: '½ day PM',
        }
      case 'OVERNIGHT':
        return {
          dayLengthIcon: <Icons.Moon dataTestid="day_length_icon" dataStatus="moon" size={isLargeScreen ? 30 : 20} />,
          dayLength: 'Overnight',
        }
      default:
        return {
          dayLengthIcon: (
            <Icons.FullSun dataTestid="day_length_icon" dataStatus="full-sun" size={isLargeScreen ? 40 : 20} />
          ),
          dayLength: 'Unknown day length',
        }
    }
  }

  const { dayLengthIcon, dayLength } = getDayLengthInfo()

  return (
    <ThemeProvider theme={theme}>
      <DayLengthWrapper>
        <DayLengthContent>
          {dayLengthIcon}
          <RightSideWrapper>
            <DayLengthText data-testid="day_length-value">{dayLength}</DayLengthText>
            {half_day_combinable && (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <TooltipWrapper>
                  <BootstrapTooltip
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener={!isLargeScreen}
                    disableHoverListener={!isLargeScreen}
                    title={
                      <React.Fragment>
                        <Typography color="inherit" data-testid="half_day_combinable_tooltip" fontWeight={'bold'}>
                          Combinable
                        </Typography>
                        <Typography fontWeight={'regular'}>
                          means that this camp has AM and PM half-day camps on the same day, at the same location, for
                          the same (or broadly overlapping) age groups. Often, a provider will allow you to sign up for
                          half-day AM camp and a half-day PM camp, and your child can stay through lunch, to create a
                          full-day camp experience. Lunch is often not provided but is supervised, and it is always best
                          to check with the provider to confirm availability and details.
                        </Typography>
                      </React.Fragment>
                    }
                    placement={'bottom'}
                    arrow
                  >
                    <Box
                      onClick={isLargeScreen ? undefined : handleTooltipToggle}
                      onMouseEnter={isLargeScreen ? handleTooltipOpen : undefined}
                      onMouseLeave={isLargeScreen ? handleTooltipClose : undefined}
                    >
                      <CombinableText data-testid="half_day_combinable">
                        combinable <Icons.HelpIcon />
                      </CombinableText>
                    </Box>
                  </BootstrapTooltip>
                </TooltipWrapper>
              </ClickAwayListener>
            )}
          </RightSideWrapper>
        </DayLengthContent>
      </DayLengthWrapper>
    </ThemeProvider>
  )
}

const DayLengthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const DayLengthContent = styled.div`
  display: flex;
  align-items: center;

  flex-direction: column;
  @media${({ theme }) => theme.device['md']} {
    flex-direction: row;
    > svg {
      margin-left: 0.25rem;
    }
  }
`
const RightSideWrapper = styled.div`
  display: flex;
  margin-left: 0.5rem;
  text-align: left;
  gap: 0;
  align-items: start;
  flex-direction: column;
`

const DayLengthText = styled.span`
  font-weight: 600;
  color: ${colors.brandGray900};
  font-size: 1rem;
  line-height: 1.5rem;
  @media${({ theme }) => theme.device['md']} {
    line-height: 30px;
    font-size: 18px;
  }
`

const TooltipWrapper = styled.div``

const CombinableText = styled.span`
  text-decoration: dotted underline;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  color: ${colors.brandGray700};
`

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} leaveDelay={2000} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#101828',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#101828',
    padding: '0.75rem',
  },
}))
