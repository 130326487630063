import { setSchoolBreaks } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch } from '@store/hooks.ts'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useSchoolBreaksFilters = (): void => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const filter_school_breaks = searchParams.get('filter_school_breaks')
  useEffect(() => {
    if (filter_school_breaks) {
      try {
        const filterSchoolBreaks = JSON.parse(filter_school_breaks) || []
        if (Array.isArray(filterSchoolBreaks)) {
          dispatch(setSchoolBreaks(filterSchoolBreaks))
          const params = new URLSearchParams(searchParams)
          params.delete('filter_school_breaks')
          const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
          window.history.replaceState(null, '', url)
        }
      } catch (e) {
        console.warn('we tried to set school breaks')
      }
    }
  }, [filter_school_breaks])
}
