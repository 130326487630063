import { Icons } from '@assets/svg'
import { theme } from '@config/theme'
import { Box } from '@mui/material'
import { BusOptionsModal } from '@stories/Components/BusOptionsModal/BusOptionsModal.tsx'
import { CampStatus } from '@stories/Components/CampStatus/CampStatus.tsx'
import mixpanel from 'mixpanel-browser'
import React, { useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'

import noCampImageFallback from '../../../assets/media/images/no-camp-image-fallback.jpg'
import { BussingAddress } from '../../../libs/types/BussingAddress.ts'

interface MainImageProps {
  image: string
  title: string
  campId: number
  providerId: number
  bussing_options?: BussingAddress[] | null
  status?: string
  openBussingOptionModal: boolean
  setOpenBussingOptionModal: (visible: boolean) => void
}

const MainImageWrapper = styled(Box)`
  max-width: 669px;
  max-height: 229px;
  position: relative;

  img {
    border-radius: 60px 20px;
    max-height: 229px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media${({ theme }) => theme.device['md']} {
      font-size: 3rem;
      border-radius: 120px 30px;
      max-height: 479px;
    }
  }

  @media${({ theme }) => theme.device['md']} {
    max-height: 479px;
  }
`

const StyledBusIconBrowser = styled(Box)`
  width: 51px;
  position: absolute;
  bottom: 1rem;
  left: -0.75rem;
  z-index: 1;
  cursor: pointer;
  display: none;
  @media ${({ theme }) => theme.device['md']} {
    display: block;
  }
`

const StyledBusIconMobile = styled(Box)`
  width: 43px;
  position: absolute;
  bottom: 1rem;
  left: -0.65rem;
  z-index: 1;
  cursor: pointer;
  display: block;
  @media ${({ theme }) => theme.device['md']} {
    display: none;
  }
`

const StyledCampStatus = styled(Box)`
  position: absolute;
  bottom: 31px;
  right: 56px;
  z-index: 1;
  // @media ${({ theme }) => theme.device['lg']} {
  //   bottom: 1rem;
  // }
`

export const MainImage: React.FC<MainImageProps> = ({
  image,
  title,
  campId,
  providerId,
  bussing_options,
  status,
  openBussingOptionModal,
  setOpenBussingOptionModal,
}) => {
  // Effect to handle custom event
  useEffect(() => {
    const handleCustomEvent = () => {
      setOpenBussingOptionModal(true)
    }

    window.addEventListener('open_bussing_modal', handleCustomEvent)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('open_bussing_modal', handleCustomEvent)
    }
  }, [])

  const handleBusIconClick = () => {
    setOpenBussingOptionModal(true)
    mixpanel.track('Clicked Bus Stop Tab', { 'Camp ID': campId, 'Provider ID': providerId })
  }

  return (
    <ThemeProvider theme={theme}>
      <MainImageWrapper data-testid="camp-page-image">
        {bussing_options && bussing_options.length > 0 && (
          <StyledBusIconBrowser data-testid="bussing_tag" onClick={handleBusIconClick}>
            <Icons.BusIcon size={51} />
          </StyledBusIconBrowser>
        )}
        {bussing_options && bussing_options.length > 0 && (
          <StyledBusIconMobile data-testid="bussing_tag_mobile" onClick={handleBusIconClick}>
            <Icons.BusIcon size={41} />
          </StyledBusIconMobile>
        )}
        <img
          data-testid="camp-main-image"
          src={image}
          alt={title}
          loading={'lazy'}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = noCampImageFallback
          }}
        />
        {status && (
          <StyledCampStatus>
            <CampStatus status={status} />
          </StyledCampStatus>
        )}
      </MainImageWrapper>
      <BusOptionsModal
        campId={campId}
        providerId={providerId}
        open={openBussingOptionModal}
        setOpen={setOpenBussingOptionModal}
        bussingOptions={bussing_options || []}
      />
    </ThemeProvider>
  )
}
