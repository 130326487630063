import Eventbus from '@libs/eventbus'
import { Box, Grid, Typography } from '@mui/material'
import { setCurrentPage } from '@store/campSearch/campSearchSlice'
import { AddToListDrawer } from '@stories/Components/AddtoList/AddToListDrawer.tsx'
import mixpanel from 'mixpanel-browser'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import CampListSortButton from '../../src/components/CampList/CampListSortButton.tsx'
import { CampListItemSkeleton } from '../components/CampList/CampListItem.skeleton.tsx'
import { CampListItem } from '../components/CampList/CampListItem.tsx'
import CampListSearchBar from '../components/CampList/CampListSearchBar.tsx'
import Paginate from '../components/Paginate/index.tsx'
import { useAppDispatch, useAppSelector } from '../store/hooks.ts'

const List: React.FC = () => {
  const dispatch = useAppDispatch()

  const { campListFiltered, isLoading, isFilteringLoading } = useAppSelector((state) => state.campSearch)
  const pagination = useAppSelector((state) => state.campSearch.pagination)

  const [favCamId, setFavCampId] = useState<number>(0)

  const handlePageChange = (page: number) => {
    dispatch(setCurrentPage(page))
  }

  useEffect(() => {
    mixpanel.track_pageview({ page: 'Camps' })
    Eventbus.on(Eventbus.RESET_PAGING, () => {
      dispatch(setCurrentPage(1))
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>Camperoni Website - Camp search</title>
        <link rel="canonical" href={`https://www.camperoni.com/camps`} />
      </Helmet>
      <Box sx={{ flexGrow: 1 }} px={2}>
        <CampListSearchBar />
        <Box pt={3} pb={4.5}>
          <Typography component="h1" variant="h4">
            Camp search
          </Typography>
        </Box>
        <Box mb={3}>
          <CampListSortButton />
        </Box>
        {isLoading || isFilteringLoading ? (
          <Grid container columnSpacing={{ xs: 1, sm: 4 }} rowSpacing={{ xs: 2, sm: 4 }}>
            {Array.from(new Array(12)).map((_, index) => (
              <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
                <CampListItemSkeleton />
              </Grid>
            ))}
          </Grid>
        ) : (
          <>
            {campListFiltered && campListFiltered.length > 0 ? (
              <Grid container columnSpacing={{ xs: 1, sm: 4 }} data-testid="camp-items-container">
                <Paginate
                  itemsPerPage={20}
                  data={campListFiltered}
                  renderItem={(item) => <CampListItem item={item} setFavCampId={setFavCampId} />}
                  currentPage={pagination.currentPage}
                  onPageChange={handlePageChange}
                />
              </Grid>
            ) : (
              <Typography variant="h6">No camps found. Try clearing your filters.</Typography>
            )}
          </>
        )}
      </Box>
      <AddToListDrawer campId={favCamId} onClose={() => setFavCampId(0)} />
    </>
  )
}

export default List
