import 'flatpickr/dist/flatpickr.min.css'

import Eventbus from '@libs/eventbus'
import { Box } from '@mui/system'
import { setDateRange } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import React, { useEffect, useRef, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import DatePicker from 'react-flatpickr'

const CalendarRangeFilter: React.FC = () => {
  const calendarRef = useRef<DatePicker>(null)
  const dispatch = useAppDispatch()
  const dateRange: [string, string] = useAppSelector((state) => state.campFilters.filters.dateRange) || []

  let initialMessageState = 'Please enter start date'

  if (dateRange[0] && dateRange[1]) {
    initialMessageState = ' '
  }

  const [datePickerMessage, setDatePickerMessage] = useState(initialMessageState)

  const [tempValue, setTempValue] = useState<string[]>(dateRange)

  useEffect(() => {
    setTempValue(dateRange)

    Eventbus.on(Eventbus.RESET_FILTERS, handleClear)
    Eventbus.on(Eventbus.CALENDAR_CLEAR_FILTER, handleClear)

    return () => {
      Eventbus.off(Eventbus.RESET_FILTERS, handleClear)
      Eventbus.off(Eventbus.CALENDAR_CLEAR_FILTER, handleClear)
    }
  }, [dateRange])

  const handleDateChange = (selectedDates: Date[]): void => {
    const dates = selectedDates.map((date) => date.toISOString().split('T')[0])
    if (dates.length === 1) {
      return setDatePickerMessage('Please enter end date')
    }

    if (dates.length === 2) {
      dispatch(setDateRange(dates))
      setTempValue(dates)
      Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
        filter: 'Date Filter',
        value: dates,
      })
      Eventbus.trigger(Eventbus.RESET_PAGING)
      return setDatePickerMessage(' ')
    }

    setDatePickerMessage('Please enter start date')
  }

  const handleClear = () => {
    if (!calendarRef?.current?.flatpickr) {
      return
    }
    calendarRef.current.flatpickr.clear()

    dispatch(setDateRange(['', '']))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Clear Date Range Filter',
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
        <Box whiteSpace={'pre-wrap'} data-testid={'datePickerMessage'} pb={'0.5rem'}>
          {datePickerMessage}
        </Box>
        <Flatpickr
          data-testid={'input-date-range-picker'}
          ref={calendarRef}
          options={{ mode: 'range', minDate: 'today', static: true, inline: true, defaultDate: tempValue }}
          onChange={handleDateChange}
        />
      </Box>
    </Box>
  )
}

export default CalendarRangeFilter
