import { Button, Typography } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Terms: React.FC = () => {
  useEffect(() => {
    mixpanel.track_pageview({ page: 'Terms' })
  }, [])

  return (
    <>
      <Helmet>
        <title>Camperoni Website - Terms & Conditions</title>
        <link rel="canonical" href={`https://www.camperoni.com/terms`} />
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom>
          Terms & Conditions
        </Typography>

        <ButtonContainer>
          <Link to="/terms/user" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              View User Terms & Conditions
            </Button>
          </Link>
          <Link to="/terms/advertiser" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="secondary">
              View Advertiser Terms & Conditions
            </Button>
          </Link>
        </ButtonContainer>
      </Container>
    </>
  )
}

export default Terms

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
`
