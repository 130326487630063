import { Icons } from '@assets/svg'
import Eventbus from '@libs/eventbus'
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { setCheckedRequirements } from '@store/campFilters/campFiltersSlice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import requirementsList from '../../../../../Camperoni_requirement.json'
import { Requirement } from '../../../../libs/types/Requirement.ts'

interface CheckedRequirements {
  [key: number]: { include: boolean; exclude: boolean }
}

const RequirementsFilter: React.FC = () => {
  const dispatch = useDispatch()

  const checkedRequirements = useSelector(
    (state: { campFilters: { filters: { checkedRequirements: CheckedRequirements } } }) =>
      state.campFilters.filters.checkedRequirements
  )

  const handleChange = (reqId: number, include: boolean, exclude: boolean) => {
    const affinityGroupName = requirementsList.find((requirement) => requirement.id === reqId)?.name || ''
    if (!include && !exclude) {
      const updatedCheckedRequirements = { ...checkedRequirements }
      delete updatedCheckedRequirements[reqId]
      dispatch(setCheckedRequirements(updatedCheckedRequirements))
    } else {
      dispatch(setCheckedRequirements({ ...checkedRequirements, [reqId]: { include, exclude } }))

      let includeOrExclude: string | null = null
      if (!include && exclude) {
        includeOrExclude = 'Exclude'
      }
      if (include && !exclude) {
        includeOrExclude = 'Include'
      }
      Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
        filter: 'Affinity Groups Clicked',
        value: `${includeOrExclude} - ${affinityGroupName}`,
      })
    }
  }

  const isChecked = (reqId: number, type: 'include' | 'exclude'): boolean => {
    if (!checkedRequirements) {
      return false
    }
    const requirement = checkedRequirements[reqId]
    return !!requirement && requirement[type]
  }

  return (
    <Box maxHeight={420} className="custom-scrollbar" sx={{ overflowY: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
        <FormGroup>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginBottom: '8px' }}>
            <div style={{ marginRight: '10px', fontSize: '14px' }}>Req.</div>
            <div style={{ fontSize: '14px' }}>Excl.</div>
          </div>

          {requirementsList.map((requirement: Requirement) => (
            <FormControlLabel
              key={requirement.id}
              label={requirement.name}
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '8px',
                marginRight: '0px',
              }}
              control={
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Checkbox
                    data-testid={`${requirement.name.toLowerCase().replace(/\s/g, '-')}-include`}
                    sx={{ border: 'none' }}
                    onChange={(event) => handleChange(requirement.id, event.target.checked, false)}
                    checked={isChecked(requirement.id, 'include')}
                  />
                  <Checkbox
                    data-testid={`${requirement.name.toLowerCase().replace(/\s/g, '-')}-exclude`}
                    sx={{ border: 'none' }}
                    onChange={(event) => handleChange(requirement.id, false, event.target.checked)}
                    checkedIcon={<Icons.CloseCheckboxIcon />}
                    checked={isChecked(requirement.id, 'exclude')}
                  />
                </div>
              }
            />
          ))}
        </FormGroup>
      </Box>
    </Box>
  )
}

export default RequirementsFilter
