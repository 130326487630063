import { Camp } from '@libs/types/Camp.ts'
import { InterestList } from '@libs/types/Interest.ts'
import { Provider } from '@libs/types/Provider.ts'
import { createSlice } from '@reduxjs/toolkit'

interface CampSearchState {
  campList: Camp[]
  interestList: InterestList[]
  providerList: Provider[]
  zipCodeList: string[]
  filterDrawerOpen: boolean
  campListFiltered: Camp[]
  isLoading: boolean
  isFilteringLoading: boolean
  pagination: {
    currentPage: number
  }
}

export const initialState: CampSearchState = {
  campList: [],
  campListFiltered: [],
  interestList: [],
  zipCodeList: [],
  providerList: [],
  filterDrawerOpen: false,
  isLoading: false,
  isFilteringLoading: false,
  pagination: {
    currentPage: 1,
  },
}

export const campSearchReducer = createSlice({
  name: 'campSearch',
  initialState,
  reducers: {
    setCampListLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setFilteringIsLoading: (state, action) => {
      state.isFilteringLoading = action.payload
    },
    getCampList: () => {},
    setCampList: (state, action) => {
      state.campList = action.payload
    },
    getZipCodeList: () => {},
    setZipCodeList: (state, action) => {
      state.zipCodeList = action.payload
    },
    getInterestList: () => {},
    setInterestList: (state, action) => {
      state.interestList = action.payload
    },
    toggleFilterDrawer: (state) => {
      state.filterDrawerOpen = !state.filterDrawerOpen
    },
    setFilteredCampList: (state, action) => {
      state.campListFiltered = action.payload
    },
    setCurrentPage: (state, action) => {
      state.pagination.currentPage = action.payload
    },
  },
})

export const {
  setCampList,
  getCampList,
  setCurrentPage,
  getZipCodeList,
  setZipCodeList,
  setInterestList,
  getInterestList,
  toggleFilterDrawer,
  setFilteredCampList,
  setCampListLoading,
  setFilteringIsLoading,
} = campSearchReducer.actions

export default campSearchReducer.reducer
