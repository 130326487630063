import {
  setRegistrationStatusFull,
  setRegistrationStatusNotYetOpen,
  setRegistrationStatusOpen,
} from '@store/campFilters/campFiltersSlice'
import { useAppDispatch } from '@store/hooks.ts'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useRegistrationStatusFilters = (): void => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const filter_registration_status_open = searchParams.get('filter_registration_status_open')
  const filter_registration_status_closed = searchParams.get('filter_registration_status_closed')
  const filter_registration_status_not_yet_open = searchParams.get('filter_registration_status_not_yet_open')
  const filter_registration_status_full = searchParams.get('filter_registration_status_full')

  useEffect(() => {
    if (filter_registration_status_open) {
      dispatch(setRegistrationStatusOpen(filter_registration_status_open === 'true'))
      const params = new URLSearchParams(searchParams)
      params.delete('filter_registration_status_open')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_registration_status_open])
  useEffect(() => {
    if (filter_registration_status_not_yet_open) {
      dispatch(setRegistrationStatusNotYetOpen(filter_registration_status_not_yet_open === 'true'))
      const params = new URLSearchParams(searchParams)
      params.delete('filter_registration_status_not_yet_open')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_registration_status_not_yet_open])
  useEffect(() => {
    if (filter_registration_status_full) {
      dispatch(setRegistrationStatusFull(filter_registration_status_closed === 'true'))
      const params = new URLSearchParams(searchParams)
      params.delete('filter_registration_status_closed')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_registration_status_closed])
}
