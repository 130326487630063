import { brandGray } from '@config/theme'
import { Box, Typography } from '@mui/material'
import { CheckIcon } from '@stories/Icons/CheckIcon/CheckIcon.tsx'
import { CloseButton } from '@stories/Icons/CloseButton/CloseButton.tsx'
import { PaperBagIcon } from '@stories/Icons/PaperBagIcon/PaperBagIcon.tsx'
import { FC } from 'react'
import styled from 'styled-components'

export interface LunchIncludedProps {
  isIncluded: boolean
}

const BoxFlex = styled(Box)`
  display: flex;
  gap: 9px;
  align-items: center;
`

const IconWrapper = styled(Box)`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledTypography = styled(Typography)`
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 24px;
`

export const LunchIncluded: FC<LunchIncludedProps> = ({ isIncluded }) => {
  return (
    <Box data-testid="lunch-included-container">
      <BoxFlex>
        <PaperBagIcon />
        <StyledTypography>Lunch Availability</StyledTypography>
      </BoxFlex>
      {isIncluded ? (
        <BoxFlex>
          <IconWrapper>
            <CheckIcon />
          </IconWrapper>
          <StyledTypography data-testid="lunch-included-text" style={{ color: '#78870F' }}>
            Yes
          </StyledTypography>
        </BoxFlex>
      ) : (
        <BoxFlex>
          <IconWrapper>
            <CloseButton color={brandGray} onClick={() => {}} />
          </IconWrapper>
          <StyledTypography data-testid="lunch-included-text" style={{ color: brandGray }}>
            No
          </StyledTypography>
        </BoxFlex>
      )}
    </Box>
  )
}
