import { colors } from '@config/theme'
import MaterialAvatar from '@mui/material/Avatar'
import React from 'react'

export type SizeVariant = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'

export interface AvatarProps {
  children: string | React.ReactNode
  src?: string
  alt?: string
  size?: SizeVariant
}

const sizes = {
  xsmall: {
    width: 20,
    height: 20,
  },
  small: {
    width: 40,
    height: 40,
  },
  medium: {
    width: 70,
    height: 70,
  },
  large: {
    width: 90,
    height: 90,
  },
  xlarge: {
    width: 100,
    height: 100,
  },
}
export const Avatar = ({ src, alt, children, size = 'small' }: AvatarProps) => {
  const { width, height } = sizes[size]
  return (
    <MaterialAvatar
      alt={alt}
      src={src}
      sx={{
        bgcolor: colors.brandBlue,
        border: '3px solid white',
        fontFamily: 'Ubuntu',
        fontSize: '14px',
        width,
        height,
      }}
    >
      {children}
    </MaterialAvatar>
  )
}
