import { Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const UserTermsSkeleton: React.FC = () => {
  return (
    <Box sx={{ padding: '1rem' }}>
      <Box sx={{ margin: '96px auto' }} display={'flex'} alignItems={'center'} flexDirection={'column'}>
        <Box display={'flex'}>
          <Skeleton variant="rectangular" height={24} width={200} sx={{ marginBottom: '2rem' }} />
        </Box>
        <Box display={'flex'}>
          <Skeleton variant="rectangular" height={48} width={375} />
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'left'}
        sx={{ maxWidth: '720px', margin: '2rem auto' }}
      >
        <Skeleton variant="rectangular" height={24} width={200} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'left'}
        sx={{ maxWidth: '720px', margin: '2rem auto' }}
      >
        <Skeleton variant="rectangular" height={24} width={200} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'left'}
        sx={{ maxWidth: '720px', margin: '2rem auto' }}
      >
        <Skeleton variant="rectangular" height={24} width={200} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
        <Skeleton variant="rectangular" height={16} width={'100%'} sx={{ marginBottom: '1rem' }} />
      </Box>
    </Box>
  )
}

export default UserTermsSkeleton
