import './index.css'

import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App.tsx'
import { persistor, store } from './store/store'

Sentry.init({
  dsn: 'https://727c694339dc9d3e58f950737bdca8aa@o4506301617405952.ingest.sentry.io/4506301619765248',
  environment: import.meta.env.VITE_APP_ENV,

  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
Sentry.replayIntegration({
  networkDetailAllowUrls: [window.location.origin, import.meta.env.VITE_API_BASE_API_URL],
  maskAllText: false,
  blockAllMedia: false,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)
