import { colors } from '@config/theme'
import React from 'react'
import styled, { css } from 'styled-components'

export type BadgeVariation =
  | 'brandRed'
  | 'brandPurple'
  | 'brandBlue'
  | 'brandGreen'
  | 'brandCyan'
  | 'brandYellow'
  | 'brandGray'

export interface BadgeProps {
  /**
   * Label text or HTML.
   */
  children: string | React.ReactNode
  /**
   * A string corresponding to the badge-component variation classes
   */
  variant?: BadgeVariation
}

const StyledBadge = styled.span<{ $colorVariant?: string; $backgroundVariant?: string; $borderColor?: string }>`
  display: inline-block;
  padding: 0.13rem 0.62rem;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1.25rem;
  text-align: center;
  border-radius: 1rem;
  ${({ $backgroundVariant }) =>
    $backgroundVariant &&
    css`
      background-color: ${$backgroundVariant};
    `}
  ${({ $colorVariant }) =>
    $colorVariant &&
    css`
      color: ${$colorVariant};
    `}
  ${({ $borderColor }) =>
    $borderColor &&
    css`
      border: 1px solid ${$borderColor};
    `}
`

const textColorToBackgroundColorMap: Record<string, string> = {
  brandRed: '#FFF4F6',
  brandPurple: '#FFF4FD',
  brandBlue: '#F3F5FF',
  brandGreen: '#F6F9E1',
  brandCyan: '#F1FCFF',
  brandYellow: '#FFF4D1',
  brandGray: colors.brandGray200,
  brandDefault: '#FFF',
}
const textColorToBorderColorMap: Record<string, string> = {
  brandRed: '#F8C7D0',
  brandPurple: '#E7B9DD',
  brandBlue: '#BAC1E8',
  brandGreen: '#BBCB48',
  brandCyan: '#75CCE3',
  brandYellow: '#ECC753',
  brandGray: colors.brandGray50,
  brandDefault: '#101828',
}
const brandToTextColorMap: Record<string, string> = {
  brandRed: '#DD2A4C',
  brandPurple: '#A44890',
  brandBlue: '#4856A0',
  brandGreen: '#647011',
  brandCyan: '#006C89',
  brandYellow: '#936F00',
  brandGray: colors.brandGray700,
  brandDefault: '#101828',
}

export const Badge = ({ variant = 'brandRed', children }: BadgeProps) => {
  const colorVariant = brandToTextColorMap[variant]
  const backgroundVariant = textColorToBackgroundColorMap[variant] satisfies string
  const borderColor = textColorToBorderColorMap[variant] satisfies string

  return (
    <StyledBadge $colorVariant={colorVariant} $backgroundVariant={backgroundVariant} $borderColor={borderColor}>
      {children}
    </StyledBadge>
  )
}
