import { colors, theme } from '@config/theme'
import React from 'react'
import styled, { ThemeProvider, WebTarget } from 'styled-components'

interface TextProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
  fontFamily?: 'Ubuntu' | 'Inter'
  textAlign?: 'left' | 'center' | 'right' | 'justify'
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize'
  textDecoration?: 'underline' | 'overline' | 'line-through'
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap'
  lineHeight?: string
  opacity?: number
  letterSpacing?: string
  fontSize?: string
  variant?: void | WebTarget | undefined | string
  color?: string
  noWrap?: boolean
  fontWeight?: 'regular' | 'semibold' | 'bold'
  padding?: string
  margin?: string
  cursorPointer?: boolean
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  mt?: string
  mb?: string
  ml?: string
  mr?: string
  pt?: string
  pb?: string
  pl?: string
  pr?: string
  mx?: string
  my?: string
  px?: string
  py?: string
  underline?: boolean
}

const StyledText = styled.p<TextProps>`
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}
  ${({ underline }) => underline && `text-decoration: underline;`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  ${({ color }) => color && `color: ${color};`}
  ${({ noWrap }) => noWrap && `white-space: nowrap;`}
  ${({ fontWeight, theme }) => fontWeight && `font-weight: ${theme.fontWeights[fontWeight]};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ cursorPointer }) => cursorPointer && `cursor: pointer;`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ textDecoration }) => textDecoration && `text-decoration: ${textDecoration};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ opacity }) => opacity !== undefined && `opacity: ${opacity};`}
  margin-top: ${(props) => props.mt || props.my || '0'};
  margin-bottom: ${(props) => props.mb || props.my || '0'};
  margin-left: ${(props) => props.ml || props.mx || '0'};
  margin-right: ${(props) => props.mr || props.mx || '0'};
  padding-top: ${(props) => props.pt || props.py || '0'};
  padding-bottom: ${(props) => props.pb || props.py || '0'};
  padding-left: ${(props) => props.pl || props.px || '0'};
  padding-right: ${(props) => props.pr || props.px || '0'};
`

export const Text: React.FC<TextProps> = ({
  fontFamily = 'Inter',
  fontSize,
  variant = 'p',
  color = colors.brandGray900,
  noWrap,
  fontWeight = 'regular',
  padding = '0rem',
  margin = '0rem',
  children,
  cursorPointer = false,
  className,
  style,
  ml,
  pl,
  mr,
  pr,
  mt,
  pt,
  mb,
  pb,
  px,
  mx,
  py,
  my,
  underline,
  ...rest
}: TextProps) => {
  const Element = StyledText
  return (
    <ThemeProvider theme={theme}>
      <Element
        ml={ml}
        pl={pl}
        pr={pr}
        mr={mr}
        mb={mb}
        pb={pb}
        mt={mt}
        pt={pt}
        mx={mx}
        my={my}
        px={px}
        py={py}
        className={className}
        style={style}
        as={variant}
        fontFamily={fontFamily}
        fontSize={fontSize}
        color={color}
        noWrap={noWrap}
        fontWeight={fontWeight}
        padding={padding}
        margin={margin}
        cursorPointer={cursorPointer}
        underline={underline}
        {...rest}
      >
        {children}
      </Element>
    </ThemeProvider>
  )
}
