import { Icons } from '@assets/svg'
import { colors } from '@config/theme'
import { api } from '@libs/api'
import { Box, useMediaQuery } from '@mui/material'
import { Text } from '@stories/Typography/Text/Text'
import mixpanel from 'mixpanel-browser'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

const LTSVG = Icons['error404lefttopsvg']
const RTSVG = Icons['error404rightopsvg']
const LBSVG = Icons['error404leftbottomsvg']
const RBSVG = Icons['error404rightbottomsvg']

const ErrorContainerWrapper = styled.div`
  padding: 10rem 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  .left-top {
    z-index: 2;
    display: none;
    @media${({ theme }) => theme.device['lg']} {
      display: block;
      position: absolute;
      left: 20%;
      top: 30%;
    }
  }
  .right-top {
    z-index: 2;
    position: absolute;
    right: 30%;
    top: 15%;
    @media${({ theme }) => theme.device['lg']} {
      position: absolute;
      right: 25%;
      top: 20%;
    }
  }
  .left-bottom {
    z-index: 2;
    position: absolute;
    bottom: 15%;
    left: 20%;
    @media${({ theme }) => theme.device['lg']} {
      position: absolute;
      bottom: 5%;
      left: 46%;
    }
  }
  .right-bottom {
    z-index: 2;
    position: absolute;
    right: 20%;
    bottom: 10%;
    @media${({ theme }) => theme.device['lg']} {
      position: absolute;
      right: 25%;
      bottom: 20%;
    }
  }
  .background-text {
    position: absolute;
    z-index: 1;
  }
`

const Container = styled.div`
  max-width: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
`

const EmailVerify = () => {
  const { key } = useParams()
  const isSmallScreen = useMediaQuery('(max-width:1200px)')
  const [errorStatus, setErrorStatus] = useState<string>('')

  useEffect(() => {
    mixpanel.track_pageview({ page: 'EmailVerify' })
    api
      .emailVerify(key as string)
      .then(() => {
        window.location.href = '/'
      })
      .catch((error) => {
        setErrorStatus(`${error?.status}`)

        mixpanel.track('Error', {
          'Error Type': 'Request Error',
          'Error Code': error?.status,
          'Request Type': 'HTTP POST',
          'Error Name': 'Email verification failed',
          'Error Group': 'Password/Verification',
          'Email Verification Key': key,
        })
      })
  }, [])

  // @ts-ignore
  return (
    <Box data-testid="email-verify-wrapper">
      {errorStatus !== '' && (
        <ErrorContainerWrapper>
          {!isSmallScreen && (
            <Text variant={'h1'} fontSize={'350px'} fontWeight="bold" color={'#F2F4F7'} className="background-text">
              {errorStatus}
            </Text>
          )}

          <Container>
            <Text
              data-testid="email-verify-error-title"
              variant={'h2'}
              fontFamily="Ubuntu"
              fontSize={isSmallScreen ? '20px' : '38px'}
              fontWeight="bold"
              color={colors.brandGray900}
            >
              Sorry, we couldn’t verify your email.
            </Text>
            <Text
              data-testid="email-verify-error-text"
              variant={'p'}
              fontSize={'18px'}
              my="2rem"
              textAlign="center"
              color={colors.brandGray900}
            >
              We weren’t able to find your account and verify your email, please try again. <br />
              <br />
              If the problem persists, please contact our support team to further assist you in verifying your account.
              <br />
              <br />
              email@camperoni.com
            </Text>
          </Container>
          <LTSVG className="left-top" />
          <RTSVG className="right-top" />
          <LBSVG className="left-bottom" size={70} />
          <RBSVG className="right-bottom" />
        </ErrorContainerWrapper>
      )}
    </Box>
  )
}

export default EmailVerify
