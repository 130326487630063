import Eventbus from '@libs/eventbus'
import { Box, FormControlLabel, Switch } from '@mui/material'
import { setHideTentative, setShowOnlyLunchIncluded } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import React from 'react'

const AdditionalOptions = () => {
  const dispatch = useAppDispatch()
  const hideTentative = useAppSelector((state) => state.campFilters.filters?.additionalOptions?.hideTentative ?? false)
  const showOnlyLunchIncluded = useAppSelector(
    (state) => state.campFilters.filters?.additionalOptions?.showOnlyLunchIncluded ?? false
  )

  function handleShowOnlyLunchIncludedChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setShowOnlyLunchIncluded(event.target.checked))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Show only lunch available',
      value: event.target.checked,
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  function handleHideTentativeChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setHideTentative(event.target.checked))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Hide Tentative',
      value: event.target.checked,
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  return (
    <>
      <Box>
        <FormControlLabel
          control={
            <Switch onChange={handleShowOnlyLunchIncludedChange} sx={{ m: 1 }} checked={showOnlyLunchIncluded} />
          }
          label="Show only lunch available"
        />
      </Box>
      <Box>
        <FormControlLabel
          control={<Switch onChange={handleHideTentativeChange} sx={{ m: 1 }} checked={hideTentative} />}
          label="Hide tentative"
        />
      </Box>
    </>
  )
}

export default AdditionalOptions
