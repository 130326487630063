import { Icons } from '@assets/svg'
import { colors, theme } from '@config/theme'
import styled, { ThemeProvider } from 'styled-components'

export interface PricePerDayProps {
  daily_price: string | null
}

const renderPriceText = (daily_price: string | null): string => {
  if (!daily_price) {
    return 'Unknown'
  }

  if (parseFloat(daily_price) === 0) {
    return 'FREE'
  }

  return `${Math.round(parseFloat(daily_price))} per day`
}
export const PricePerDay = ({ daily_price }: PricePerDayProps) => {
  return (
    <ThemeProvider theme={theme}>
      <AgeWrapper>
        <Icons.DollarIcon />
        <PriceText data-testid="daily_price-value">{renderPriceText(daily_price)}</PriceText>
      </AgeWrapper>
    </ThemeProvider>
  )
}

// Styled Components
const AgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: ${colors.brandGray900};
  flex-direction: column;

  svg {
    width: 20px;
    height: 20px;
  }
  @media${({ theme }) => theme.device['md']} {
    flex-direction: row;
    svg {
      width: 36px;
      height: 36px;
      margin-right: 0.25rem;
    }
  }
`

const PriceText = styled.span`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  @media${({ theme }) => theme.device['md']} {
    line-height: 30px;
    font-size: 18px;
  }
`
