import { UserList } from '@libs/types/UserList.ts'
import { createSlice } from '@reduxjs/toolkit'

export interface UserListState {
  userList: UserList[]
  isLoading: boolean
}

export const initialState: UserListState = {
  userList: [],
  isLoading: false,
}

export const userListReducer = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    setUserListLoading: (state, action) => {
      state.isLoading = action.payload
    },
    getUserList: () => {},
    setUserList: (state, action) => {
      state.userList = action.payload
    },
  },
})

export const { getUserList, setUserList, setUserListLoading } = userListReducer.actions

export default userListReducer.reducer
