import { Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  width: 100%;
  flex-direction: column;
  padding: 1rem;

  span {
    max-width: 100%;
  }

  div {
    width: 100%;
    max-width: 100%;
    @media${({ theme }) => theme.device['lg']} {
      flex-direction: row;
    }
  }

  @media${({ theme }) => theme.device['lg']} {
    padding: 10rem 4rem;
    flex-direction: row;
    .left-side {
      padding: 0rem 4rem;
    }
  }
`
const ThankYouPageSkeleton: React.FC = () => {
  return (
    <Box>
      <StyledBox
        display={'flex'}
        sx={{
          gap: 1,
        }}
        pb={3}
      >
        <div className={'left-side'}>
          <Skeleton variant="rectangular" height={'48px'} style={{ marginBottom: '0.5rem' }} />
          <Skeleton variant="rectangular" height={'21px'} style={{ marginBottom: '0.75rem' }} />
          <Skeleton variant="rectangular" height={'21px'} style={{ marginBottom: '0.75rem' }} />
          <Skeleton variant="rectangular" height={'21px'} style={{ marginBottom: '0.75rem' }} />
          <Skeleton variant="rectangular" height={'21px'} style={{ marginBottom: '1.5rem' }} />
          <Skeleton variant="rectangular" height={'21px'} style={{ marginBottom: '0' }} />
        </div>
        <div className="right-side">
          <Skeleton variant="rounded" width={608} height={405} style={{ marginBottom: '0.75rem' }} />
        </div>
      </StyledBox>
    </Box>
  )
}

export default ThankYouPageSkeleton
