export const dayLength = (dayLength: 'FULL' | 'HALF_AM' | 'HALF_PM' | 'OVERNIGHT'): string => {
  const dictionary = {
    FULL: 'Full day',
    Full: 'Full day',
    HALF_AM: 'Half Day AM',
    HALF_PM: 'Half Day PM',
    OVERNIGHT: 'Overnight',
  }
  return dictionary[dayLength]
}
