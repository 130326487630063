import Eventbus from '@libs/eventbus'
import { Box, ToggleButton } from '@mui/material'
import { setKidsAges } from '@store/campFilters/campFiltersSlice.ts'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const MIN_AGE = 3
const MAX_AGE = 18

const StyledBox = styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1280px) {
    flex-wrap: wrap;
  }
`

const KidsAges = () => {
  const dispatch = useAppDispatch()
  let kidsAges = useAppSelector((state) => state.campFilters.filters.kidsAges)
  if (!Array.isArray(kidsAges)) {
    kidsAges = []
  }

  const availableAges = useMemo(() => {
    const ages = []
    for (let i = MIN_AGE; i < MAX_AGE; i++) {
      ages.push(i)
    }
    return ages
  }, [])

  const handleChangeAges = (_event: React.MouseEvent<HTMLElement>, newValue: number) => {
    if (kidsAges.includes(newValue)) {
      dispatch(setKidsAges(kidsAges.filter((age) => age !== newValue)))
    } else {
      dispatch(setKidsAges([...kidsAges, newValue]))
      Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
        filter: 'Kids Ages',
        value: newValue,
      })
    }
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  return (
    <>
      <StyledBox>
        {availableAges.map((age) => (
          <ToggleButton
            selected={kidsAges.includes(age)}
            onChange={handleChangeAges}
            key={`toggle-button-key-${age}`}
            value={age}
            aria-label={`${age}`}
            data-testid={`ages-testid-${age}`}
          >
            {age}
          </ToggleButton>
        ))}
      </StyledBox>
    </>
  )
}

export default KidsAges
