import { Camp } from '@libs/types/Camp.ts'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Divider, Pagination, PaginationItem, Typography } from '@mui/material'
import React, { useEffect } from 'react'

interface PaginateProps {
  data: Camp[]
  itemsPerPage?: number
  renderItem?: (item: Camp) => JSX.Element
  currentPage: number
  onPageChange: (page: number) => void
}

const Paginate = ({ data, renderItem, itemsPerPage = 12, currentPage, onPageChange }: PaginateProps) => {
  const maxPage = Math.ceil(data.length / itemsPerPage)

  const jump = (page: number) => {
    const pageNumber = Math.max(1, page)
    onPageChange(Math.min(pageNumber, maxPage))
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [currentPage])

  useEffect(() => {
    const pageNumber = Math.min(maxPage, currentPage)
    onPageChange(pageNumber)
  }, [data])

  const start = (currentPage - 1) * itemsPerPage
  const end = start + itemsPerPage
  const slicedData = data.slice(start, end)

  return (
    <>
      {slicedData.map((item, index) => (
        <React.Fragment key={`sliced-item-${index}`}>{renderItem && renderItem(item)}</React.Fragment>
      ))}
      <div style={{ width: '100%' }}>
        <Divider />
        <Pagination
          count={maxPage}
          page={currentPage}
          onChange={(_e, page) => jump(page)}
          color="standard"
          size="large"
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: () => (
                  <>
                    <ArrowBackIcon fontSize="small" sx={{ mr: 1 }} />
                    <Typography variant="body2">Previous</Typography>
                  </>
                ),
                next: () => (
                  <>
                    <Typography variant="body2" data-testid="next-page">
                      Next
                    </Typography>
                    <ArrowForwardIcon fontSize="small" sx={{ ml: 1 }} />
                  </>
                ),
              }}
              {...item}
            />
          )}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            my: 4,
            px: 2,
            '& .MuiPagination-ul': {
              width: '100%',
              '& > li:first-child': {
                marginRight: 'auto',
                '& > button:hover': {
                  backgroundColor: 'transparent',
                },
              },
              '& > li:last-child': {
                marginLeft: 'auto',
                '& > button:hover': {
                  backgroundColor: 'transparent',
                },
              },
            },
          }}
        />
      </div>
    </>
  )
}

export default Paginate
