import { Skeleton } from '@mui/material'

export const CampListItemSkeleton = () => {
  return (
    <>
      <Skeleton variant="rounded" width={'100%'} height={130} />
      <Skeleton variant="rounded" width="90%" height={20} sx={{ mt: 1 }} />
      <Skeleton variant="rounded" width="80%" height={20} sx={{ mt: 1 }} />
    </>
  )
}
