export const brandRed: string = '#DD2A4C'
export const brandPurple: string = '#A44890'
export const brandBlue: string = '#4856A0'
export const brandGreen: string = '#A9BB23'
export const brandCyan: string = '#00B3E3'
export const brandYellow: string = '#F1B700'

export const brandGray: string = '#475467'
export const brandGray50: string = '#EAECF0'
export const brandGray100: string = '#FCFCFD'
export const brandGray200: string = '#F9FAFB'
export const brandGray400: string = '#98A2B3'
export const brandGray500: string = '#667085'
export const brandGray300: string = '#D0D5DD'
export const brandGray700: string = '#344054'
export const brandGray900: string = '#101828'

export const brandError500: string = '#F04438'

const brandBlue50: string = '#EFF8FF'
const brandLight: string = '#fff'
const brandWhite: string = '#fff'

interface Size {
  xs: string
  sm: string
  md: string
  lg: string
  xl: string
  xxl: string
}

interface FontWeight {
  regular: number
  semibold: number
  bold: number
}

export interface SpacingProps {
  $mt?: number
  $mb?: number
  $ml?: number
  $mr?: number
  $m?: number
  $pt?: number
  $pb?: number
  $pl?: number
  $pr?: number
  $p?: number
}

const size: Size = {
  xs: '0px', // for small screen mobile
  sm: '414px', // for mobile screen
  md: '767px', // for tablets
  lg: '1280px', // for laptops
  xl: '1440px', // for desktop / monitors
  xxl: '1920px', // for big screens
}
const fontWeights: FontWeight = {
  regular: 400,
  semibold: 600,
  bold: 700,
}

const colors = {
  brandRed: brandRed,
  brandPurple: brandPurple,
  brandBlue: brandBlue,
  brandGreen: brandGreen,
  brandCyan: brandCyan,
  brandYellow: brandYellow,
  brandWhite: brandWhite,
  brandGray: brandGray,
  brandGray100: brandGray100,
  brandGray200: brandGray200,
  brandGray300: brandGray300,
  brandGray400: brandGray400,
  brandGray500: brandGray500,
  brandGray700: brandGray700,
  brandGray900: brandGray900,
  brandBlue50: brandBlue50,
  brandGray50: brandGray50,
  brandDefault: brandGray900,
  brandError500: brandError500,
}

const theme = {
  colors: {
    'brand-red': brandRed,
    'brand-purple': brandPurple,
    'brand-blue': brandBlue,
    'brand-green': brandGreen,
    'brand-cyan': brandCyan,
    'brand-yellow': brandYellow,
    'brand-gray': brandGray,
    'brand-gray-400': brandGray400,
    'brand-gray-500': brandGray500,
    'brand-gray-700': brandGray700,
    'brand-gray-900': brandGray900,
    'brand-light': brandLight,
    'brand-white': brandWhite,
    'brand-default': brandGray900,
  },
  device: {
    xs: `(min-width: ${size.xs})`,
    sm: `(min-width: ${size.sm})`,
    md: `(min-width: ${size.md})`,
    lg: `(min-width: ${size.lg})`,
    xl: `(min-width: ${size.xl})`,
    xxl: `(min-width: ${size.xxl})`,
  },
  spacing: [
    '0rem',
    '0.25rem', // 4px
    '0.5rem', // 8px
    '0.75rem', // 12px
    '1rem', // 16px
    '1.25rem', // 20px
    '1.5rem', // 24px
    '1.75rem', // 28px
    '2rem', // 32px
    '2.25rem', //36px
    '2.5rem', //40px
  ],
  fontWeights,
}
export { theme, colors }
