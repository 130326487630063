import { colors, theme } from '@config/theme'
import { Box, Typography } from '@mui/material'
import React from 'react'
import styled, { ThemeProvider } from 'styled-components'

interface CampStatusProps {
  status: 'Open' | 'Waiting list' | 'Full' | 'Closed' | string
}

const CampStatusWrapper = styled(Box)`
  background-color: ${colors.brandWhite};
  padding: 8px 20px;
  display: flex;
  align-items: center;
  gap: 9px;
  border-radius: 50px;
`

const StatusBadge = styled(Box)`
  width: 12px;
  height: 12px;
  border-radius: 50%;
`

export const CampStatus: React.FC<CampStatusProps> = ({ status }) => {
  let statusBadgeColor

  switch (status) {
    case 'Open':
      statusBadgeColor = '#17B26A'
      break
    case 'Waiting list':
      statusBadgeColor = colors.brandYellow
      break
    case 'Full':
      statusBadgeColor = colors.brandRed
      break
    case 'Closed':
      statusBadgeColor = colors.brandGray700
      break
    default:
      statusBadgeColor = colors.brandGray300
  }

  return (
    <ThemeProvider theme={theme}>
      <CampStatusWrapper>
        <StatusBadge sx={{ backgroundColor: statusBadgeColor }} data-testid={'status-badge'} />
        <Typography sx={{ fontSize: '16px', fontWeight: 600, color: colors.brandGray900 }}>{status}</Typography>
      </CampStatusWrapper>
    </ThemeProvider>
  )
}
