import { Drawer } from '@mui/material'
import { toggleFilterDrawer } from '@store/campSearch/campSearchSlice.ts'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'

import CampListSidebar from './CampListSidebar/index.tsx'

const CampListSearchFilterMobile = () => {
  const dispatch = useAppDispatch()
  const filterDrawerOpen = useAppSelector((state) => state.campSearch.filterDrawerOpen)

  function handleFilterDrawerOnClose() {
    dispatch(toggleFilterDrawer())
  }

  return (
    <>
      <Drawer open={filterDrawerOpen} anchor={'bottom'} onClose={handleFilterDrawerOnClose}>
        <CampListSidebar />
      </Drawer>
    </>
  )
}

export default CampListSearchFilterMobile
