import { setCheckedRequirements } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch } from '@store/hooks.ts'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useAudienceFilters = (): void => {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()

  const filterCheckedInclude = searchParams.get('filter_checked_affinity_include')
  const filterCheckedExclude = searchParams.get('filter_checked_affinity_exclude')

  useEffect(() => {
    if (!filterCheckedInclude || !filterCheckedExclude) {
      return
    }

    const include = JSON.parse(filterCheckedInclude) || []
    const exclude = JSON.parse(filterCheckedExclude) || []

    try {
      if (Array.isArray(include) && Array.isArray(exclude)) {
        const checkedRequirements: Record<number, { include: boolean; exclude: boolean }> = {
          ...include.reduce((acc, val) => ({ ...acc, [val]: { include: true, exclude: false } }), {}),
          ...exclude.reduce((acc, val) => ({ ...acc, [val]: { include: false, exclude: true } }), {}),
        }
        dispatch(setCheckedRequirements(checkedRequirements))
        const params = new URLSearchParams(searchParams)

        params.delete('filter_checked_affinity_include')
        params.delete('filter_checked_affinity_exclude')
        const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
        window.history.replaceState(null, '', url)
      }
    } catch (e) {
      console.warn('Error setting affinity group filters', e)
    }
  }, [filterCheckedInclude, filterCheckedExclude])
}
