import styled from 'styled-components'

const HeaderWrapper = styled.nav`
  position: sticky;
  z-index: 100;
  width: 100%;
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    padding-right: 12px;
    height: 72px;
    @media${({ theme }) => theme.device['md']} {
      height: 80px;
      padding-left: 30px;
      padding-right: 30px;
    }
    .left-part {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .brand-logo {
        width: 155px;
        display: flex;
        align-items: center;
        padding-right: 1rem;
        img {
          width: 100%;
          height: auto;
        }
      }
      .nav-list {
        display: none;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        padding-left: 1.4rem;
        margin: 0;
        li {
          a {
            display: block;
            padding: 0.5rem 0;
            margin: 0 1rem;
            color: ${({ theme }) => theme.colors['brand-gray']};
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-decoration: none;
            &.active {
              border-bottom: 2px solid ${({ theme }) => theme.colors['brand-gray-500']};
            }
          }
        }
        @media${({ theme }) => theme.device['md']} {
          display: flex;
        }
      }
    }
    .right-part {
      &-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media${({ theme }) => theme.device['md']} {
          display: none;
        }
      }
      &-desktop {
        justify-content: space-between;
        align-items: center;
        display: none;
        @media${({ theme }) => theme.device['md']} {
          display: flex;
        }
      }
    }
  }
`

const SideNavWrapper = styled.nav<{ $navOpen: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  left: ${({ $navOpen }) => ($navOpen ? '0px' : '-100%')};
  top: 0;
  display: block;
  background-color: ${({ theme }) => theme.colors['brand-white']};
  transition: all 0.5s ease;
  @media${({ theme }) => theme.device['md']} {
    padding-left: 30px;
    padding-right: 30px;
  }
  .nav-top {
    padding-left: 24px;
    padding-right: 12px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media${({ theme }) => theme.device['md']} {
      height: 80px;
    }
    .brand-logo {
      width: 155px;
      display: flex;
      align-items: center;
      padding-right: 1rem;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  @media${({ theme }) => theme.device['md']} {
    top: 80px;
    display: none;
  }
`
export { HeaderWrapper, SideNavWrapper }
