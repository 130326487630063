import { Icons } from '@assets/svg'
import { colors, theme } from '@config/theme'
import styled, { ThemeProvider } from 'styled-components'

export interface AgeYearsOldProps {
  ages_from: number | null
  ages_to: number | null
}

const renderAgeText = (ages_from: number | null, ages_to: number | null): string => {
  if (typeof ages_from === 'number' && ages_from === 0 && ages_to === null) {
    return 'All ages'
  }

  if ((!ages_from && !ages_to) || (ages_to && ages_to < ages_from!) || (ages_from === 0 && ages_to === 0)) {
    return 'Ages unknown'
  }

  if (!ages_from && ages_to === 0) {
    return 'Ages unknown'
  }

  if (!ages_from && ages_to && ages_to > 0) {
    return `Up to ${ages_to} ${ages_to === 1 ? 'year old' : 'years old'}`
  }

  if (ages_from && (!ages_to || ages_to === 0)) {
    return `${ages_from}+ years old`
  }

  if (ages_from === ages_to) {
    return `${ages_from} year-olds`
  }

  return `${ages_from}-${ages_to} years old`
}
export const AgeYearsOld = ({ ages_from, ages_to }: AgeYearsOldProps) => {
  return (
    <ThemeProvider theme={theme}>
      <AgeWrapper>
        <Icons.ChildIcon />
        <AgeText data-testid="ages-value">{renderAgeText(ages_from, ages_to)}</AgeText>
      </AgeWrapper>
    </ThemeProvider>
  )
}

// Styled Components
const AgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: ${colors.brandGray900};

  svg {
    width: 20px;
    height: 20px;
  }

  flex-direction: column;
  @media${({ theme }) => theme.device['md']} {
    flex-direction: row;
    svg {
      width: 36px;
      height: 36px;
      margin-right: 0.25rem;
    }
  }
`

const AgeText = styled.span`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  @media${({ theme }) => theme.device['md']} {
    line-height: 30px;
    font-size: 18px;
  }
`
