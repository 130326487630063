import { Icons } from '@assets/svg'
import { colors } from '@config/theme'
import { Box, Dialog, Typography } from '@mui/material'
import { CloseButton } from '@stories/Icons/CloseButton/CloseButton.tsx'
import mixpanel from 'mixpanel-browser'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { BussingAddress } from '../../../libs/types/BussingAddress.ts'

interface BusOptionsModalProps {
  campId: number
  providerId: number
  open: boolean
  setOpen: (visible: boolean) => void
  bussingOptions: BussingAddress[]
}

const BussingOptionsModalWrapper = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: transparent;
    font-family: 'Inter', sans-serif;
  }

  .MuiPaper-root {
    max-width: 799px;
    max-height: 946px;
    padding: 20px 16px;
    background-color: ${colors.brandWhite};
    width: 100%;
    height: 90%;
    border-radius: 12px;
    box-shadow:
      0 20px 24px -4px rgba(16, 24, 40, 0.08),
      0 8px 8px -4px rgba(16, 24, 40, 0.03);
  }
`

const ScrollableAddressContainer = styled(Box)`
  overflow-y: scroll;
  padding-right: 8px;

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 2px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    width: 2px;
    background-color: ${colors.brandGray400}; /* Scrollbar thumb color */
    border-radius: 10px; /* Rounded scrollbar thumb */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* Scrollbar track color */
  }
`

const StyledAddressItem = styled(Box)`
  padding: 8px;
  border-bottom: 1px solid ${colors.brandGray300};

  @media${({ theme }) => theme.device['md']} {
    padding: 8px 16px;
  }

  &:hover {
    background-color: ${colors.brandGray200};
  }

  &:last-child {
    border-bottom: none;
  }
`

const StyledAddressTypography = styled(Typography)`
  font-size: 1rem;
  color: #000;
`

export const BusOptionsModal: React.FC<BusOptionsModalProps> = ({
  campId,
  providerId,
  open,
  setOpen,
  bussingOptions,
}) => {
  const formattedPrefix = (str: string) => {
    if (!str) {
      return 'Bus Stop'
    }

    return str.endsWith(',') ? str.slice(0, -1) : str
  }

  return (
    <BussingOptionsModalWrapper onClose={() => setOpen(false)} open={open} data-testid="bussing-options-modal">
      <Box alignItems={'flex-start'} display={'flex'} justifyContent={'space-between'} pb={2}>
        <Typography
          sx={{
            fontSize: { sm: '16px', md: '18px' },
            fontWeight: 600,
            color: colors.brandGray900,
            fontFamily: 'Inter',
          }}
        >
          Bus drop-off and pick-up locations
        </Typography>
        <CloseButton onClick={() => setOpen(false)} color={colors.brandGray500} />
      </Box>
      {bussingOptions && bussingOptions.length > 0 ? (
        <ScrollableAddressContainer data-testid="bussing_options_list">
          {bussingOptions.map((bussingOption, index) => {
            return (
              <StyledAddressItem key={index}>
                <StyledAddressTypography data-testid="prefix_field" style={{ fontWeight: 600 }}>
                  {formattedPrefix(bussingOption.prefix.trim())}
                </StyledAddressTypography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                    alignItems: { sm: 'flex-start', md: 'center' },
                    justifyContent: { sm: 'flex-start', md: 'space-between' },
                  }}
                >
                  <StyledAddressTypography data-testid="address_field">
                    {bussingOption.addressName}
                  </StyledAddressTypography>
                  <Link
                    to={`https://maps.google.com/?q=${bussingOption.addressName}`}
                    target="_blank"
                    data-testid={`bussing_address_google_maps_link_${index + 1}`}
                    onClick={() => {
                      mixpanel.track('Clicked Get Directions to Bus Stop', {
                        'Camp ID': campId,
                        'Provider ID': providerId,
                      })
                    }}
                    style={{ display: 'flex', gap: '8px', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Typography sx={{ color: colors.brandBlue, fontWeight: 600 }}>Get directions</Typography>
                    <Icons.TurnRightIcon />
                  </Link>
                </Box>
              </StyledAddressItem>
            )
          })}
        </ScrollableAddressContainer>
      ) : (
        <StyledAddressTypography
          data-testid="bussing_note_section"
          sx={{ textAlign: 'center', color: colors.brandGray400 }}
        >
          We do not have data on bus stops for this listing.
        </StyledAddressTypography>
      )}
    </BussingOptionsModalWrapper>
  )
}
