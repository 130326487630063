import { Provider } from '@libs/types/Provider.ts'
import { Schoolbreak } from '@libs/types/Schoolbreak.ts'
import { createSlice } from '@reduxjs/toolkit'

interface MetroSliceState {
  metro_id: number
  sessionStorageSchoolBreaksList: Schoolbreak[]
  sessionStorageProviderList: Provider[]
}

export const initialState: MetroSliceState = {
  metro_id: 1,
  sessionStorageSchoolBreaksList: [],
  sessionStorageProviderList: [],
}

export const metroReducer = createSlice({
  name: 'metro',
  initialState,
  reducers: {
    getMetro: () => {},
    setMetro: (state, action) => {
      state.metro_id = action.payload
    },
    setSessionStorageSchoolBreaksList: (state, action) => {
      state.sessionStorageSchoolBreaksList = action.payload
      sessionStorage['api:schoolbreaks'] = JSON.stringify(action.payload)
    },
    getSessionStorageSchoolBreaksList: () => {},
    setSessionStorageProviderList: (state, action) => {
      state.sessionStorageProviderList = action.payload
      sessionStorage['api:providerList'] = JSON.stringify(action.payload)
    },
  },
})

export const {
  setMetro,
  getMetro,

  getSessionStorageSchoolBreaksList,
  setSessionStorageSchoolBreaksList,
  setSessionStorageProviderList,
} = metroReducer.actions

export default metroReducer.reducer
