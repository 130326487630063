import { createSlice } from '@reduxjs/toolkit'
interface GlobalState {
  isLoading: boolean
}

export const initialState: GlobalState = {
  isLoading: false,
}

export const globalReducer = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const { setIsLoading } = globalReducer.actions

export default globalReducer.reducer
