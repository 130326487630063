import { setHideTentative, setShowOnlyLunchIncluded } from '@store/campFilters/campFiltersSlice.ts'
import { useAppDispatch } from '@store/hooks.ts'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useAdditionalOptionsFilters = (): void => {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()
  const filter_hide_tentative = searchParams.get('filter_hide_tentative')
  const filter_show_only_lunch_included = searchParams.get('filter_show_only_lunch_included')

  useEffect(() => {
    if (filter_show_only_lunch_included) {
      dispatch(setShowOnlyLunchIncluded(filter_show_only_lunch_included === 'true'))
      const params = new URLSearchParams(searchParams)
      params.delete('filter_show_only_lunch_included')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_show_only_lunch_included])
  useEffect(() => {
    if (filter_hide_tentative) {
      dispatch(setHideTentative(filter_hide_tentative === 'true'))
      const params = new URLSearchParams(searchParams)
      params.delete('filter_hide_tentative')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_hide_tentative])
}
