import { convertToISO } from '@decorators/date-isoFormat'
import { isFutureDate } from '@decorators/is-future-date.ts'
import { isPastDate } from '@decorators/is-past-date.ts'
import { setDateRange } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch } from '@store/hooks'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useDateRangeFilters = (): void => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const filter_dates_range = searchParams.get('filter_dates_range') || ''

  useEffect(() => {
    try {
      if (filter_dates_range) {
        const datesArray: string[] = JSON.parse(filter_dates_range)

        const params = new URLSearchParams(searchParams)
        params.delete('filter_dates_range')
        const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
        window.history.replaceState(null, '', url)

        if (Array.isArray(datesArray) && datesArray.length === 2) {
          if (isPastDate(datesArray[0]) && isPastDate(datesArray[1])) {
            return
          }
          if (isPastDate(datesArray[0]) && isFutureDate(datesArray[1])) {
            datesArray[0] = convertToISO(new Date().toString())
          }

          dispatch(setDateRange([convertToISO(datesArray[0]), convertToISO(datesArray[1])]))
        }
      }
    } catch (error) {
      console.warn('Error while processing date range filters:', error)
    }
  }, [filter_dates_range])
}
