import { configureStore } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import { reducer } from './rootReducer'
import rootSaga from './rootSaga'

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
  key: 'root:22',
  storage,
  // whitelist: [],
  blacklist: ['campFilters', 'campSearch'],
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
})

// Add a listener to update window.__REACT_REDUX_STATE__ whenever the store changes
store.subscribe(() => {
  if (process.env.NODE_ENV !== 'production') {
    // @ts-ignore
    window.__REACT_REDUX_STATE__ = store.getState() // Update the global state object
  }
})

export const persistor = persistStore(store)
sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
