import { colors } from '@config/theme'
import React from 'react'
import styled from 'styled-components'

const DotSpan = styled.span`
  height: 8px;
  width: 8px;
  background-color: ${colors.brandBlue};
  border-radius: 50%;
  display: inline-block;
`

export const ActiveFilterIcon: React.FC = () => {
  return <DotSpan />
}
