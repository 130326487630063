import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { debounce, InputAdornment, TextField, useTheme } from '@mui/material'
import { setSearchTerm } from '@store/campFilters/campFiltersSlice'
import { useAppSelector } from '@store/hooks.ts'
import { store } from '@store/store.ts'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Eventbus from '../../libs/eventbus.ts'

const debouncedSearch = debounce((value) => {
  store.dispatch(setSearchTerm(value))
  if (value) {
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Search for value',
      value: value,
    })
  }
}, 800)

const CampListSearchBar: React.FC = () => {
  const theme = useTheme()
  const searchTermValue = useAppSelector((state) => state.campFilters.filters.searchTerm)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchParams] = useSearchParams()

  const search = searchParams.get('search')

  useEffect(() => {
    if (searchTermValue) {
      setSearchTerm(searchTermValue)
    }
  }, [searchTermValue])

  useEffect(() => {
    if (search) {
      setSearchTerm(search)
      debouncedSearch(search)
    }
  }, [search])

  useEffect(() => {
    Eventbus.on(Eventbus.RESET_FILTERS, () => {
      setSearchTerm('')
    })

    return Eventbus.off(Eventbus.RESET_FILTERS, () => {
      setSearchTerm('')
    })
  })

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value)
    debouncedSearch(event.target.value)
  }

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        ),
      }}
      placeholder="Search with camp name, provider, city, facility or affinity group"
      variant="outlined"
      size="small"
      fullWidth
      value={searchTerm}
      onChange={handleChange}
      sx={{
        mb: 2,
        '& .MuiInputBase-root': {
          borderRadius: '20px',
          py: 0.2,
          boxShadow: '0px 0px 0px 4px rgba(152, 162, 179, 0.14), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          backgroundColor: theme.palette.background.paper,
        },
      }}
    />
  )
}

export default CampListSearchBar
