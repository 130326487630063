import { CampFilters, CampFiltersSort } from '@store/campFilters/campFiltersSlice'
import { RootState } from '@store/store'
import { Camp } from 'src/libs/types/Camp.ts'

export const getCampListSelector = (state: RootState): Camp[] => {
  return state.campSearch.campList ?? null
}

export const getFiltersSelector = (state: RootState): { filters: CampFilters; sort: CampFiltersSort } => {
  return { filters: state.campFilters.filters, sort: state.campFilters.sort }
}
