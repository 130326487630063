import Eventbus from '@libs/eventbus'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material'
import { setCheckedInterests } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import React, { useCallback } from 'react'

import { InterestList } from '../../../../libs/types/Interest'

interface ChildInterestProps {
  childInterests: InterestList[]
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isChecked: (interest: InterestList) => boolean
}

const ChildInterest = ({ isChecked, childInterests, onChange }: ChildInterestProps): JSX.Element => (
  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
    {childInterests.map((child: InterestList) => (
      <FormControlLabel
        key={child.id}
        checked={isChecked(child)}
        label={child.name}
        control={<Checkbox onChange={onChange} data-label-value={child.name} />}
        value={child.id}
      />
    ))}
  </Box>
)

const InterestsFilter = () => {
  const dispatch = useAppDispatch()
  const interestList = useAppSelector((state) => state.campFilters.interestList)
  const checkedInterests = useAppSelector((state) => state.campFilters.filters.checkedInterests)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.stopPropagation()
    const interestId = event.target.value
    const interestName = (event.target.parentNode as HTMLInputElement).dataset.labelValue || ''
    const childrenIds = interestList
      .find((interest) => interest.id === Number(interestId))
      ?.children?.map((child) => child.id)

    // Has children
    if (childrenIds && childrenIds.length > 0) {
      if (event.target.checked) {
        const newCheckedInterests = [...new Set([...checkedInterests, ...childrenIds])]

        dispatch(setCheckedInterests(newCheckedInterests))
        Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
          filter: 'Interests',
          value: interestName,
        })
        return
      }
      const newCheckedInterests = checkedInterests.filter((interest) => !childrenIds?.includes(interest))
      dispatch(setCheckedInterests(newCheckedInterests))
      Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
        filter: 'Interests',
        value: newCheckedInterests,
      })
      return
    }

    //  No children
    if (event.target.checked) {
      dispatch(setCheckedInterests([...new Set([...checkedInterests, Number(event.target.value)])]))
      Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
        filter: 'Interests',
        value: interestName,
      })
      return
    }
    dispatch(setCheckedInterests(checkedInterests.filter((interest) => interest !== Number(event.target.value))))
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const isChecked = useCallback(
    (interest: InterestList) => {
      const childrenIds = interest.children?.map((child) => child.id)
      if (childrenIds && childrenIds.length > 0) {
        return childrenIds?.every((child) => checkedInterests?.includes(child)) && childrenIds?.length > 0
      }
      return checkedInterests?.includes(interest.id)
    },
    [checkedInterests]
  )

  const isParentIndeterminate = useCallback(
    (interest: InterestList) => {
      return (
        !interest.children?.every((child) => checkedInterests?.includes(child.id)) &&
        interest.children?.some((child) => checkedInterests?.includes(child.id)) &&
        interest.children?.length > 0
      )
    },
    [checkedInterests]
  )

  return (
    <Box>
      <Box
        maxHeight={420}
        sx={{
          overflowY: 'auto',
        }}
      >
        {interestList.map((interest) => (
          <Box key={interest.id}>
            {interest.children?.length > 0 ? (
              <Accordion disableGutters elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${interest.id}-content`}
                  aria-label="Expand"
                  id={`panel-${interest.id}-header`}
                  sx={{ pl: 0, '& .MuiAccordionSummary-content': { margin: 0 } }}
                >
                  <FormGroup>
                    <FormControlLabel
                      sx={{ color: interest.color }}
                      label={interest.name}
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <Checkbox
                          value={interest.id}
                          data-label-value={interest.name}
                          onChange={handleChange}
                          checked={isChecked(interest)}
                          indeterminate={isParentIndeterminate(interest)}
                        />
                      }
                    />
                  </FormGroup>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <ChildInterest
                    childInterests={interest.children as InterestList[]}
                    onChange={handleChange}
                    isChecked={isChecked}
                  />
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                <FormGroup>
                  <FormControlLabel
                    sx={{ color: interest.color }}
                    label={interest.name}
                    control={
                      <Checkbox
                        value={interest.id}
                        data-label-value={interest.name}
                        onChange={handleChange}
                        checked={isChecked(interest)}
                        indeterminate={isParentIndeterminate(interest)}
                      />
                    }
                  />
                </FormGroup>
                <ChildInterest
                  childInterests={interest.children as InterestList[]}
                  onChange={handleChange}
                  isChecked={isChecked}
                />
              </>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default InterestsFilter
