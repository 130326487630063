import { getCampList } from '@store/campSearch/campSearchSlice.ts'
import { useAppDispatch } from '@store/hooks.ts'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'

import Footer from '../components/Footer'
import Header from '../components/Header'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const MainContainer = styled.main`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`

const FooterContainer = styled.footer`
  margin-top: auto;
`
const SharableFavoritesLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch()

  dispatch(getCampList())

  return (
    <Wrapper>
      <Header pageTitle={'none'} />
      <MainContainer>{children}</MainContainer>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </Wrapper>
  )
}

export default SharableFavoritesLayout
