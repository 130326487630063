import * as Sentry from '@sentry/browser'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import mixpanel from 'mixpanel-browser'
import ReactGA from 'react-ga4'

import HomepageCounters from '../../public/homepageCounters.json'
import redis from './lockr'
import { Address } from './types/Address.ts'
import { BussingAddress } from './types/BussingAddress.ts'
import { Camp } from './types/Camp'
import { City } from './types/City'
import { Distance } from './types/Distance.ts'
import { Facility } from './types/Facility.ts'
import { Interest } from './types/Interest'
import { SuccessFullLogin } from './types/LoginResponse'
import { Provider } from './types/Provider'
import { Purchaseoption } from './types/Purchaseoption'
import { Requirement } from './types/Requirement'
import { Schoolbreak } from './types/Schoolbreak'
import { User } from './types/User'
import { UserByKey } from './types/UserByKey'
import { UserList } from './types/UserList.ts'
import { Zipcode } from './types/Zipcode.ts'

export interface apiResponse<T> {
  data: T
  status?: number
}

const capi = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  adapter: 'fetch',
})

let originalRequestTime: number | null = null

// Manually apply axios-retry to this specific request
const applyRetryLogic = () => {
  axiosRetry(capi, {
    retries: 2,
    retryCondition: () => {
      const currentTime = Date.now()

      // Retry if the original request was made less than 30 seconds ago
      return !(originalRequestTime !== null && currentTime - originalRequestTime > 30000)
    },
    onRetry: () => {
      // Set originalRequestTime on the first retry
      if (originalRequestTime === null) {
        originalRequestTime = Date.now()
      }
    },
  })
}

/* private methods starts here */
const getUserKey = (): string => {
  const key = redis.get('key') as string
  if (key) {
    return key
  }

  const user = redis.get('user') as User
  if (user?.key) {
    return user.key
  }

  return redis.get('key', '') as string
}

const postUserLogin = async (email: string, password: string): Promise<apiResponse<SuccessFullLogin>> => {
  return await capi.post<any, apiResponse<SuccessFullLogin>>('/dj-rest-auth/login/', {
    username: email,
    password: password,
  })
}

const postUserRegister = async (
  email: string,
  password: string,
  first_name: string,
  last_name: string
): Promise<void> => {
  const payload: any = {
    email: email,
    username: email,
    password1: password,
    password2: password,
    first_name: first_name,
  }

  if (last_name) {
    payload.last_name = last_name
  }

  return await capi.post<any, void>('/dj-rest-auth/registration/', payload)
}

const fetchCamps = async (metroId: number = 1): Promise<apiResponse<Camp[]>> => {
  return await capi.get<Camp, apiResponse<Camp[]>>(`/api/camps_new/?metro_id=${metroId}&format=json`)
}
const fetchCampWithId = async (campId: string | number | undefined): Promise<apiResponse<Camp>> => {
  return await capi.get<Camp, apiResponse<Camp>>(`/api/camps_new/${campId}/?format=json`)
}
const fetchPurchaseOptions = async (): Promise<apiResponse<Purchaseoption[]>> => {
  return await capi.get<Purchaseoption, apiResponse<Purchaseoption[]>>('/api/purchaseoptions/?format=json')
}
const fetchRequirements = async (): Promise<apiResponse<Requirement[]>> => {
  return await capi.get<Requirement, apiResponse<Requirement[]>>('/api/requirements/?format=json')
}
const fetchSchoolBreaks = async (): Promise<apiResponse<Schoolbreak[]>> => {
  return await capi.get<Schoolbreak, apiResponse<Schoolbreak[]>>('/api/schoolbreaks/?format=json&future_only=1')
}

const fetchInterests = async (): Promise<apiResponse<Interest[]>> => {
  return await capi.get<Interest, apiResponse<Interest[]>>('/api/interests/?format=json')
}

const fetchProviders = async (): Promise<apiResponse<Provider[]>> => {
  return await capi.get<Provider, apiResponse<Provider[]>>('/api/providers/?format=json')
}

const fetchFacilities = async (): Promise<apiResponse<Facility[]>> => {
  return await capi.get<Facility, apiResponse<Facility[]>>('/api/facilities/?format=json')
}
const fetchCities = async (): Promise<apiResponse<City[]>> => {
  return await capi.get<City, apiResponse<City[]>>('/api/cities/?format=json')
}
const fetchZipcodes = async (): Promise<apiResponse<Zipcode[]>> => {
  return await capi.get<Zipcode, apiResponse<Zipcode[]>>('/api/zipcodes/?format=json')
}

const fetchDistances = async (zipcodeID: string): Promise<apiResponse<Distance[]>> => {
  return await capi.get<Distance, apiResponse<Distance[]>>(`/api/distances/?format=json&zipcode_id=${zipcodeID}`)
}

const fetchAddresses = async (): Promise<apiResponse<Address[]>> => {
  return await capi.get<Address, apiResponse<Address[]>>('/api/addresses/?format=json')
}

const fetchBussingAddresses = async (): Promise<apiResponse<BussingAddress[]>> => {
  return await capi.get<BussingAddress, apiResponse<BussingAddress[]>>('/api/bussingaddresses/?format=json')
}

const fetchUserList = async (): Promise<apiResponse<UserList[]>> => {
  const key = getUserKey()
  if (!key) {
    return { data: [], status: 200 }
  }

  return await capi.get<User, apiResponse<UserList[]>>(`/api/lists/?format=json`, {
    headers: {
      Authorization: `Token ${key}`,
    },
  })
}

const patchUserWithFirstNameLastName = async (name: string): Promise<apiResponse<Partial<User>>> => {
  const key = getUserKey()
  const [firstName, ...lastName] = name.split(' ').filter(Boolean)
  const userData = {
    first_name: firstName,
    last_name: lastName.join(' '),
  }
  return await capi.patch<User, apiResponse<User>>(`/dj-rest-auth/user/`, userData, {
    headers: {
      Authorization: `Token ${key}`,
    },
  })
}

const patchUserWithUtmTags = async (id: number, userData: Record<string, unknown>): Promise<apiResponse<User>> => {
  const key = getUserKey()

  return await capi.patch<User, apiResponse<User>>(`/api/users/${id}/`, userData, {
    headers: {
      Authorization: `Token ${key}`,
    },
  })
}

/* Public Methods */
const getCamps = (metroId: number = 1): Promise<apiResponse<Camp[]>> => {
  return fetchCamps(metroId)
}
const getCampWithId = (campId: string | number | undefined): Promise<apiResponse<Camp>> => {
  return redis.getOrSetEx(fetchCampWithId.bind(this, campId), `api:camp:${campId}`, 6000) as Promise<apiResponse<Camp>>
}
const getPurchaseOptions = (): Promise<apiResponse<Purchaseoption[]>> => {
  return redis.getOrSetEx(fetchPurchaseOptions, 'api:purchaseOptions', 6000) as Promise<apiResponse<Purchaseoption[]>>
}
const getCities = (): Promise<apiResponse<City[]>> => {
  return redis.getOrSetEx(fetchCities, 'api:cities', 6000) as Promise<apiResponse<City[]>>
}

const getZipcodes = async (metroId: number = 1): Promise<Record<number, number>> => {
  const key = 'api:v2:zipcodes'
  let zipcodes = redis.get(key, false) as Record<number, number>
  if (!zipcodes) {
    const response = await fetchZipcodes()

    zipcodes = response.data
      .filter((zipcodeEl: Zipcode) => zipcodeEl.metro === metroId)
      .reduce((acc, curr) => ({ ...acc, [curr.id]: curr.zipcode }), {}) as Record<number, number>
    redis.setEx(key, 6000, zipcodes)
  }

  return new Promise((resolve) => resolve(zipcodes))
}

const getDistances = async (enteredZipcode: string): Promise<Distance[] | undefined> => {
  try {
    // Retrieve all zipcodes
    const zipcodes = await getZipcodes()

    // Find the corresponding zipcode ID
    const zipcodeId = Object.keys(zipcodes).find((key) => zipcodes[+key] === +enteredZipcode)
    let distances: Distance[] | undefined

    // If a valid zipcodeId is found, fetch distances
    if (zipcodeId) {
      const response = await fetchDistances(zipcodeId)
      distances = response.data
    }

    // Cache the distances for 10 minutes
    redis.setEx('api:v2:distances', 6000, distances)

    // Return the distances (or undefined if no zipcodeId was found)
    return distances
  } catch (error) {
    redis.rm('api:v2:distances')

    // Log the error and return undefined if something goes wrong
    console.error('Error fetching distances:', error)

    return undefined
  }
}
const getAddresses = async (): Promise<Record<number, string>> => {
  const key = 'api:v2:addresses'
  let addresses = redis.get(key, false) as Record<number, string>
  if (!addresses) {
    const response = await fetchAddresses()
    addresses = response.data.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.address }), {}) as Record<number, string>
    redis.setEx(key, 6000, addresses)
  }

  return new Promise((resolve) => resolve(addresses))
}

const getInterests = (): Promise<apiResponse<Interest[]>> => {
  return redis.getOrSetEx(fetchInterests, 'api:interests', 6000) as Promise<apiResponse<Interest[]>>
}
const getProviders = (): Promise<apiResponse<Provider[]>> => {
  return redis.getOrSetEx(fetchProviders, 'api:providers', 6000) as Promise<apiResponse<Provider[]>>
}

const getFacilities = async (): Promise<Record<number, string>> => {
  const key = 'api:v2:facilities'
  let facilities = redis.get(key, false) as Record<number, string>
  if (!facilities) {
    const response = await fetchFacilities()
    facilities = response.data.reduce((acc, curr) => ({ ...acc, [curr.id]: { ...curr } }), {}) as Record<number, string>
    redis.setEx(key, 6000, facilities)
  }

  return new Promise((resolve) => resolve(facilities))
}

const getBussingAddresses = async (): Promise<Record<number, any>> => {
  const key = 'api:v2:bussing:addresses'
  let bussingAddresses = redis.get(key, false) as Record<number, any>
  if (!bussingAddresses) {
    const response = await fetchBussingAddresses()
    bussingAddresses = response.data.reduce((acc, curr) => ({ ...acc, [curr.id]: { ...curr } }), {}) as Record<
      number,
      any
    >
    redis.setEx(key, 6000, bussingAddresses)
  }

  return new Promise((resolve) => resolve(bussingAddresses))
}

const getSchoolBreaks = (): Promise<apiResponse<Schoolbreak[]>> => {
  return redis.getOrSetEx(fetchSchoolBreaks, 'api:schoolbreaks', 6000) as Promise<apiResponse<Schoolbreak[]>>
}
const getRequirements = (): Promise<apiResponse<Requirement[]>> => {
  return redis.getOrSetEx(fetchRequirements, 'api:requirements', 6000) as Promise<apiResponse<Requirement[]>>
}

const getUserList = (): Promise<apiResponse<UserList[]>> => {
  if (!isUserLoggedIn()) {
    return Promise.resolve({ data: [], status: 200 })
  }
  return redis.getOrSetEx(fetchUserList, 'api:userList', 300) as Promise<apiResponse<UserList[]>>
}

const getSharableListById = async (
  sharableListId: string
): Promise<apiResponse<{ name: string; camps: number[]; camps_decorated: Camp[] }>> => {
  return await capi.get<any, apiResponse<{ name: string; camps: number[]; camps_decorated: Camp[] }>>(
    `/api/shared_list/${sharableListId}/`
  )
}

const createUserList = async (name: string): Promise<apiResponse<UserList>> => {
  applyRetryLogic()
  const key = getUserKey()

  return await capi.post<User, apiResponse<UserList>>(
    `/api/lists/`,
    { name },
    {
      headers: {
        Authorization: `Token ${key}`,
      },
    }
  )
}

const deleteUserList = async (list_id: number): Promise<apiResponse<any>> => {
  const key = getUserKey()

  return await capi.delete<any, apiResponse<any>>(`/api/lists/${list_id}/`, {
    headers: {
      Authorization: `Token ${key}`,
    },
  })
}

const renameUserList = async (list_id: number, name: string): Promise<apiResponse<any>> => {
  const key = getUserKey()

  return await capi.patch<any, apiResponse<any>>(
    `/api/lists/${list_id}/`,
    { name },
    {
      headers: {
        Authorization: `Token ${key}`,
      },
    }
  )
}

const addCampUserList = async (list_id: number, camp_id: number): Promise<apiResponse<UserList[]>> => {
  applyRetryLogic()
  const key = getUserKey()

  return await capi.patch<UserList, apiResponse<UserList[]>>(
    `/api/add_to_list/`,
    { list_id, camp_id },
    {
      headers: {
        Authorization: `Token ${key}`,
      },
    }
  )
}

const deleteCampUserList = async (list_id: number, camp_id: number): Promise<apiResponse<UserList[]>> => {
  applyRetryLogic()
  const key = getUserKey()

  return await capi.patch<UserList, apiResponse<UserList[]>>(
    `/api/remove_from_list/`,
    { list_id, camp_id },
    {
      headers: {
        Authorization: `Token ${key}`,
      },
    }
  )
}
const getHomepageCampCount = async (): Promise<number> => {
  return HomepageCounters?.camps ?? 2831
}
const getCampProvidersCount = async (): Promise<number> => {
  return HomepageCounters?.providers ?? 270
}

/* Actions */

const fetchUserByKey = async (key: string): Promise<apiResponse<UserByKey>> => {
  return await capi.get<UserByKey, apiResponse<UserByKey>>('/dj-rest-auth/user/', {
    headers: {
      Authorization: `Token ${key}`,
    },
  })
}
const fetchUserById = async (id: number): Promise<apiResponse<User>> => {
  const key = getUserKey()

  return await capi.get<User, apiResponse<User>>(`/api/users/${id}/`, {
    headers: {
      Authorization: `Token ${key}`,
    },
  })
}
const login = async (email: string, password: string): Promise<User> => {
  const loginResponse = await postUserLogin(email, password)
  redis.set('key', loginResponse.data.key)

  const userByKey = await fetchUserByKey(loginResponse.data.key)

  const user = await fetchUserById(userByKey.data.pk)
  user.data.key = loginResponse.data.key

  ReactGA.set({ userId: user.data.id })
  mixpanel.identify(user.data.id.toString())
  Sentry.setUser({ id: user.data.id.toString() })
  redis.set('user', user.data)
  return user.data
}

const register = async (email: string, password: string, name: string): Promise<User> => {
  const [firstName, ...lastName] = name.split(' ').filter(Boolean)
  const userData = {
    first_name: firstName,
    last_name: lastName.length !== 0 ? lastName.join(' ') : '',
  }
  await postUserRegister(email, password, userData.first_name, userData.last_name)
  const loginResponse = await login(email, password)

  redis.set('user', loginResponse)

  await patchUserWithUtmTags(loginResponse.id, {
    utm_campaign: redis.get('utm_campaign', ''),
    utm_source: redis.get('utm_source', ''),
    utm_medium: redis.get('utm_medium', ''),
    utm_term: redis.get('utm_term', ''),
    utm_content: redis.get('utm_content', ''),
  })
  return loginResponse
}

const resetPassword = async (email: string): Promise<{ data: { detail: string } }> => {
  return await capi.post<User, { data: { detail: string } }>('/dj-rest-auth/password/reset/', { email })
}

const resetPasswordConfirm = async (
  uid: string | undefined,
  token: string | undefined,
  new_password1: string,
  new_password2: string
): Promise<apiResponse<{ detail: string }>> => {
  return await capi.post<User, { data: { detail: string } }>('dj-rest-auth/password/reset/confirm/', {
    uid,
    token,
    new_password1,
    new_password2,
  })
}

const emailVerify = async (key: string): Promise<apiResponse<{ detail: string }>> => {
  return await capi.post<User, { data: { detail: string } }>('/dj-rest-auth/registration/verify-email/', { key })
}

const googleSignIn = async (authResponse: { code: string }): Promise<User> => {
  const { code } = authResponse
  const loginResponse = await capi
    .post<any, { data: { key: string } }>('/dj-rest-auth/google_authcode/', { authorization_code: code })
    .then((response) => {
      return response.data
    })

  redis.set('key', loginResponse.key)

  const userByKey = await fetchUserByKey(loginResponse.key)

  const user = await fetchUserById(userByKey.data.pk)
  user.data.key = loginResponse.key

  mixpanel.identify(user.data.id as unknown as string)
  Sentry.setUser({ id: user.data.id })

  await patchUserWithUtmTags(user.data.id, {
    utm_campaign: redis.get('utm_campaign', ''),
    utm_source: redis.get('utm_source', ''),
    utm_medium: redis.get('utm_medium', ''),
    utm_term: redis.get('utm_term', ''),
    utm_content: redis.get('utm_content', ''),
  })

  redis.set('user', user.data)
  return user.data
}

const googleConnect = async (authResponse: { code: string }): Promise<boolean> => {
  const { code } = authResponse

  // const loginResponse = await capi
  //   .post<any, { data: { key: string } }>('/dj-rest-auth/google_authcode/', { authorization_code: code })
  //   .then((response) => {
  //     return response.data
  //   })

  const proxyResponse = await axios
    .post<any, { data: { id_token: string } }>('https://proxy.camperoni.com/auth/google', {
      code,
    })
    .then((response) => response.data)

  const key = getUserKey()

  await capi.post<User, apiResponse<SuccessFullLogin>>(
    '/dj-rest-auth/google/connect/',
    {
      access_token: proxyResponse.id_token,
    },
    {
      headers: {
        Authorization: `Token ${key}`,
      },
    }
  )

  return true
}

const updateUserAccount = async (name: string): Promise<apiResponse<Partial<User>>> => {
  return await patchUserWithFirstNameLastName(name)
}

const updateUserPassword = async (
  old_password: string,
  new_password1: string,
  new_password2: string
): Promise<apiResponse<{ detail: string }>> => {
  const key = getUserKey()
  const userData = { old_password, new_password1, new_password2 }

  return await capi.post<{ detail: string }, apiResponse<{ detail: string }>>(
    `/dj-rest-auth/password/change/`,
    userData,
    {
      headers: {
        Authorization: `Token ${key}`,
      },
    }
  )
}
const isUserLoggedIn = (): boolean => {
  const user = redis.get('user') as User
  return Boolean(user?.id && user?.key)
}
const getUserFirstName = (): string => {
  const user = redis.get('user') as User
  return user?.first_name || ''
}
const logout = (): void => {
  redis.rm('user')
  redis.rm('key')
  mixpanel.reset()
}

export const api = {
  login,
  logout,
  register,
  emailVerify,
  googleSignIn,
  resetPassword,
  googleConnect,
  isUserLoggedIn,
  getUserFirstName,
  updateUserAccount,
  updateUserPassword,
  resetPasswordConfirm,

  getUserList,
  createUserList,
  renameUserList,
  deleteUserList,
  addCampUserList,
  deleteCampUserList,
  getSharableListById,

  getCamps,
  getCities,
  getZipcodes,
  getDistances,
  getAddresses,
  getInterests,
  getProviders,
  getFacilities,
  getCampWithId,
  getBussingAddresses,

  getSchoolBreaks,
  getRequirements,
  getPurchaseOptions,
  getHomepageCampCount,
  getCampProvidersCount,
}
