import { setCheckedInterests } from '@store/campFilters/campFiltersSlice.ts'
import { useAppDispatch } from '@store/hooks'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useInterestsFilters = (): void => {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()

  const filter_checked_interests = searchParams.get('filter_checked_interests')
  useEffect(() => {
    if (filter_checked_interests) {
      try {
        const filterCheckedInterests = JSON.parse(filter_checked_interests) || []
        if (Array.isArray(filterCheckedInterests)) {
          dispatch(setCheckedInterests(filterCheckedInterests))
          const params = new URLSearchParams(searchParams)
          params.delete('filter_checked_interests')
          const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
          window.history.replaceState(null, '', url)
        }
      } catch (e) {
        console.warn('tried to set interests but failed')
      }
    }
  }, [filter_checked_interests])
}
