import { interestToBadgeVariationMap, interestToColorMap } from '@decorators/interestToBadgeVariationMap'

import { Interest, InterestList } from '../types/Interest.ts'

export const decorateInterestsToTree = (interests: Interest[] = []): InterestList[] => {
  const map: any = {}
  const res: InterestList[] = []
  let node: Interest, i

  for (i = 0; i < interests.length; i += 1) {
    const interest = interests[i]

    map[interest.id] = i
    ;(interest as InterestList).children = []
    ;(interest as InterestList).color = interestToColorMap[interest.name]
    ;(interest as InterestList).badgeVariation = interestToBadgeVariationMap[interest.name]
  }

  for (i = 0; i < interests.length; i += 1) {
    node = interests[i]
    if (node.parent_interest === undefined) {
      continue
    }

    if (node.parent_interest === null) {
      res.push(node as InterestList)
    } else {
      const parent = interests[map[node.parent_interest]] as InterestList
      node.color = parent.color
      node.badgeVariation = parent.badgeVariation
      parent.children.push(node)
    }
  }

  res.forEach(sortChildrenAlphabetically)
  return res
}
const sortChildrenAlphabetically = (entry: InterestList): Interest[] => {
  return entry.children.sort((a, b) => a.name.localeCompare(b.name))
}
export const decorateInterestsToBadgeVariant = (interests: any = []): any => {
  const map: any = {}
  const interestsLen = interests.length
  for (let i = 0; i < interestsLen; i++) {
    const o = interests[i]
    if (o.children) {
      o.children.forEach((obj: any) => {
        map[obj.id] = {
          color: interestToBadgeVariationMap[o.name] || interestToBadgeVariationMap['Default'],
          name: obj.name,
        }
      })
    }

    map[o.id] = {
      color: interestToBadgeVariationMap[o.name] || interestToBadgeVariationMap['Default'],
      name: o.name,
    }
  }

  return map
}
