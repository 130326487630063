import { theme } from '@config/theme'
import { Box, ClickAwayListener, Tooltip, tooltipClasses, TooltipProps, Typography, useMediaQuery } from '@mui/material'
import { Button } from '@stories/Theme/Button/Button.tsx'
import mixpanel from 'mixpanel-browser'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

type Props = {
  campId: number
  providerId: number
  camperoni_registration_link: string
}

const AddViaEasyEnroll = ({ campId, providerId, camperoni_registration_link }: Props) => {
  const [open, setOpen] = React.useState(false)
  const isLargeScreen: boolean = useMediaQuery(theme.device['md'])
  const timeoutRef = React.useRef<number | null>(null)

  const handleTooltipClose = () => {
    if (!isLargeScreen) {
      return
    }
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const handleTooltipToggle = () => {
    setOpen((prev) => {
      const newState = !prev

      // If the tooltip is being opened, set a timeout to close it after 5 seconds
      if (newState) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current) // Clear any previous timeout
        }

        timeoutRef.current = window.setTimeout(() => {
          setOpen(false)
          timeoutRef.current = null // Clean up reference
        }, 5000)
      } else {
        // If the tooltip is being closed manually, clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
          timeoutRef.current = null
        }
      }

      return newState
    })
  }

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box sx={{ flex: 1, maxWidth: isLargeScreen ? '198px' : '244px', marginRight: isLargeScreen ? '10px' : '8px' }}>
        <Link to={camperoni_registration_link} target={'_blank'} data-testid="easyenroll_button">
          <Button
            testid="easyenroll_button_element"
            onClick={() => {
              mixpanel.track('Clicked Camperoni Registration Link', {
                'Camp ID': campId,
                'Provider ID': providerId,
              })
            }}
            rounded={true}
            size="large"
            style={{
              width: '100%',
              fontSize: '1rem',
              backgroundColor: theme.colors['brand-purple'],
              borderRadius: '40px',
            }}
            title="EasyEnroll"
            variant="primary"
          />
        </Link>
        <BootstrapTooltip
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener={!isLargeScreen}
          disableHoverListener={!isLargeScreen}
          title={
            <Box data-testid="easyenroll_tooltip">
              <Typography color="inherit" data-testid="easyenroll_tooltip_title-value" fontWeight={'bold'} mb={1}>
                EasyEnroll makes it easy to register quickly
              </Typography>
              <Typography fontWeight={'regular'} data-testid="easyenroll_tooltip_text-value">
                Providers offer EasyEnroll with Camperoni to make it quick and easy to reserve a spot for your child -
                no logging into another system, and no long forms. The price is the same or cheaper than registering
                directly with the provider.
              </Typography>
            </Box>
          }
          placement={'bottom'}
          arrow
        >
          <Box
            onClick={isLargeScreen ? undefined : handleTooltipToggle}
            onMouseEnter={isLargeScreen ? handleTooltipOpen : undefined}
            onMouseLeave={isLargeScreen ? handleTooltipClose : undefined}
          >
            <StyledText data-testid="easyenroll_learn_more_button">Learn more</StyledText>
          </Box>
        </BootstrapTooltip>
      </Box>
    </ClickAwayListener>
  )
}

export default AddViaEasyEnroll

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#101828',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#101828',
    padding: '0.75rem',
  },
}))

const StyledText = styled(Typography)`
  cursor: pointer;
  text-align: center;
  margin-top: 4px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #475467;

  @media${({ theme }) => theme.device['md']} {
    text-decoration: underline;
  }
`
