import Eventbus from '@libs/eventbus'
import React, { useEffect } from 'react'

const CleanLayout: React.FC = () => {
  useEffect(() => {
    Eventbus.trigger(Eventbus.RESET_FILTERS)

    document.cookie
      .split(';')
      .forEach(
        (cookie) =>
          (document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`))
      )
    Eventbus.trigger(Eventbus.RESET_FILTERS, {}, () => {
      // localStorage.clear()
      window.location.href = '/'
    })
  }, [])

  return <></>
}

export default CleanLayout
