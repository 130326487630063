import Eventbus from '@libs/eventbus'
import { SlotComponentProps } from '@mui/base'
import { Box, Slider, ToggleButton, Typography } from '@mui/material'
import { SliderComponentsPropsOverrides, SliderOwnerState } from '@mui/material/Slider/Slider'
import {
  setDayLengthDropOffTime,
  setDayLengthLength,
  setDayLengthPickUpTime,
} from '@store/campFilters/campFiltersSlice'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const dayLengths = [
  { value: 'all', label: 'All' },
  { value: 'full', label: 'Full day' },
  { value: 'halfAm', label: 'Half day AM' },
  { value: 'halfPm', label: 'Half day PM' },
  { value: 'overnight', label: 'Overnight' },
]

const min = 4.3
const max = 23.644646

const timeSeries = [
  {
    value: min,
    label: 'Any',
  },
  {
    value: 6,
    label: '6:00 AM',
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 10,
  },
  {
    value: 11,
  },
  {
    value: 12,
  },
  {
    value: 13,
  },
  {
    value: 14,
  },
  {
    value: 15,
  },
  {
    value: 16,
  },
  {
    value: 17,
  },
  {
    value: 18,
  },
  {
    value: 19,
  },
  {
    value: 20,
  },
  {
    value: 21,
  },
  {
    value: 22,
    label: '22:00 PM',
  },
  {
    value: max,
    label: 'Any',
  },
]

const dropOffMarks = timeSeries

const pickUPMarks = timeSeries

const transformValuesToAny = (values: number[]): (string | number)[] => {
  return values.map((value) => {
    if (value === min || value === max) {
      return 'Any'
    }
    return value
  })
}
const transformValueToAny = (value: number): string => {
  if (value === min) {
    return 'Any'
  }
  if (value === max) {
    return 'Any'
  }
  const suffix = value > 12 ? 'PM' : 'AM'
  if (value > 12) {
    value -= 12
  }
  return `${value}:00 ${suffix}`
}
const dropOffValuetext = (value: number): string => {
  return transformValueToAny(value)
}

const pickUpValuetext = (value: number): string => {
  return transformValueToAny(value)
}

const StyledBox = styled(Box)`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1280px) {
    flex-wrap: wrap;
  }
`

const MyLabelTypography = styled(Typography)`
  position: relative;
  top: -24px;
  font-size: 14px !important;
  display: flex;
  justify-content: space-between;
  padding: 0 8%;
`

const DayLength = () => {
  const dispatch = useAppDispatch()
  const dropOffTimeRange = useAppSelector((state) => state.campFilters.filters.dayLength.dropOffTimeAm)
  const pickUpTimeRange = useAppSelector((state) => state.campFilters.filters.dayLength.pickUpTimePm)
  const dayLength = useAppSelector((state) => state.campFilters.filters.dayLength.length)

  const [dropOffTimeTempValue, setDropOffTimeTempValue] = useState<number[]>(dropOffTimeRange)
  const [pickUpTimeTempValue, setPickUpTimeTempValue] = useState<number[]>(pickUpTimeRange)
  useEffect(() => {
    setDropOffTimeTempValue(dropOffTimeRange)
    setPickUpTimeTempValue(pickUpTimeRange)
  }, [dropOffTimeRange, pickUpTimeRange])

  const handleDropOffTimeChange = (_event: Event, newTempValue: number | number[]) => {
    setDropOffTimeTempValue(newTempValue as number[])
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const handlePickUpTimeChange = (_event: Event, newTempValue: number | number[]) => {
    setPickUpTimeTempValue(newTempValue as number[])
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const handlePickUpTimeRangeChange = (_event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      return
    }
    if (newValue[0] === max) {
      newValue[0] = 22
    }
    if (newValue[1] === min) {
      newValue[1] = 6
    }

    dispatch(setDayLengthPickUpTime(newValue))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Pick Up Time',
      value: transformValuesToAny(newValue),
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const handleDropOffTimeRangeChange = (_event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      return
    }
    if (newValue[0] === max) {
      newValue[0] = 22
    }
    if (newValue[1] === min) {
      newValue[1] = 6
    }
    dispatch(setDayLengthDropOffTime(newValue))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Drop Off Time',
      value: transformValuesToAny(newValue),
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const handleDayLengthChange = (_event: React.MouseEvent<HTMLElement>, newValue: string) => {
    dispatch(setDayLengthLength(newValue))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Day Length',
      value: newValue,
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  return (
    <>
      <StyledBox mb={2}>
        {dayLengths.map((l) => (
          <ToggleButton onClick={handleDayLengthChange} key={l.value} value={l.value} selected={l.value === dayLength}>
            <Typography noWrap variant="body2">
              {l.label}
            </Typography>
          </ToggleButton>
        ))}
      </StyledBox>
      <Box mb={2}>
        <Typography fontWeight={600} variant="body1" mb={2}>
          Drop off time
        </Typography>
        <Box px={3} pt={4}>
          <Slider
            slotProps={{
              input: {
                'data-testid': 'slider-drop-off',
              } as SlotComponentProps<'input', SliderComponentsPropsOverrides, SliderOwnerState>,
            }}
            disableSwap
            getAriaLabel={() => 'Drop off time'}
            marks={dropOffMarks}
            max={max}
            min={min}
            step={null}
            value={dropOffTimeTempValue}
            valueLabelDisplay="on"
            valueLabelFormat={dropOffValuetext}
            onChange={handleDropOffTimeChange}
            onChangeCommitted={handleDropOffTimeRangeChange}
          />
          <MyLabelTypography>
            <span>6:00 AM</span>
            <span>10:00 PM</span>
          </MyLabelTypography>
        </Box>
      </Box>
      <Box>
        <Typography fontWeight={600} variant="body1" mb={2}>
          Pick up time
        </Typography>
        <Box px={3} pt={4}>
          <Slider
            slotProps={{
              input: {
                'data-testid': 'slider-pick-up',
              } as SlotComponentProps<'input', SliderComponentsPropsOverrides, SliderOwnerState>,
            }}
            disableSwap
            getAriaLabel={() => 'Pick up time'}
            marks={pickUPMarks}
            max={max}
            min={min}
            step={null}
            value={pickUpTimeTempValue}
            valueLabelDisplay="on"
            valueLabelFormat={pickUpValuetext}
            onChange={handlePickUpTimeChange}
            onChangeCommitted={handlePickUpTimeRangeChange}
          />
          <MyLabelTypography>
            <span>6:00 AM</span>
            <span>10:00 PM</span>
          </MyLabelTypography>
        </Box>
      </Box>
    </>
  )
}

export default DayLength
