import { setKidsAges } from '@store/campFilters/campFiltersSlice.ts'
import { useAppDispatch } from '@store/hooks.ts'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useKidsAgesFilters = (): void => {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()
  const filter_kidsages = searchParams.get('filter_kidsages')

  useEffect(() => {
    if (filter_kidsages) {
      try {
        const filterKidsAges = JSON.parse(filter_kidsages) || []
        if (Array.isArray(filterKidsAges)) {
          dispatch(setKidsAges(filterKidsAges))
          const params = new URLSearchParams(searchParams)
          params.delete('filter_kidsages')
          const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
          window.history.replaceState(null, '', url)
        }
      } catch (e) {
        console.warn('we tried to set kidsages')
      }
    }
  }, [filter_kidsages])
}
