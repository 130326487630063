import { setProviders } from '@store/campFilters/campFiltersSlice.ts'
import { useAppDispatch } from '@store/hooks'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useProviderFilters = (): void => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const filter_selected_providers = searchParams.get('filter_selected_providers')

  useEffect(() => {
    if (filter_selected_providers) {
      try {
        const filterSelectedProviders = JSON.parse(filter_selected_providers) || []
        if (Array.isArray(filterSelectedProviders)) {
          dispatch(setProviders(filterSelectedProviders.map((x: number | string) => x.toString())))
          const params = new URLSearchParams(searchParams)
          params.delete('filter_selected_providers')
          const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
          window.history.replaceState(null, '', url)
        }
      } catch (e) {
        console.warn('tried to set providers but failed')
      }
    }
  }, [filter_selected_providers])
}
