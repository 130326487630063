import {
  setDistanceAllowBussing,
  setDistanceAllowOvernight,
  setDistanceIsZipCodeIncluded,
  setDistanceMiles,
  setDistanceZipCode,
} from '@store/campFilters/campFiltersSlice.ts'
import { useAppDispatch } from '@store/hooks'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useDistanceFilters = (): void => {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()

  const filter_distance_zipcode = searchParams.get('filter_distance_zipcode')
  const filter_max_distance = searchParams.get('filter_max_distance')
  const filter_bussing_option = searchParams.get('filter_bussing_option')
  const filter_include_overnight_location = searchParams.get('filter_include_overnight_location')

  const isValidZipCode = (zip: string) => /^\d{5}$/.test(zip)
  const isValidMilesDistance = (miles: string) => /^([1-9][0-9]?|100)$/.test(miles)

  useEffect(() => {
    if (filter_distance_zipcode && parseInt(filter_distance_zipcode) && isValidZipCode(filter_distance_zipcode)) {
      dispatch(setDistanceZipCode(filter_distance_zipcode))
      dispatch(setDistanceIsZipCodeIncluded(true))
      const params = new URLSearchParams(searchParams)
      params.delete('filter_distance_zipcode')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_distance_zipcode])

  useEffect(() => {
    if (filter_max_distance && parseInt(filter_max_distance) && isValidMilesDistance(filter_max_distance)) {
      const maxDistance = parseInt(filter_max_distance)
      if (!isNaN(maxDistance) && maxDistance >= 0 && maxDistance <= 100) {
        dispatch(setDistanceMiles(filter_max_distance))
        const params = new URLSearchParams(searchParams)
        params.delete('filter_max_distance')
        const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
        window.history.replaceState(null, '', url)
      }
    }
  }, [filter_max_distance])

  useEffect(() => {
    if (filter_bussing_option) {
      dispatch(setDistanceAllowBussing(filter_bussing_option !== 'false'))
      const params = new URLSearchParams(searchParams)
      params.delete('filter_bussing_option')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_bussing_option])

  useEffect(() => {
    if (filter_include_overnight_location) {
      dispatch(setDistanceAllowOvernight(filter_include_overnight_location !== 'false'))
      const params = new URLSearchParams(searchParams)
      params.delete('filter_include_overnight_location')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_bussing_option])
}
