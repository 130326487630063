import React from 'react'
import styled from 'styled-components'

const CheckIconStyled = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

export const CheckIcon: React.FC = () => {
  return (
    <CheckIconStyled>
      <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.6364 1L4.63636 9L1 5.36364"
          stroke="#78870F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </CheckIconStyled>
  )
}
