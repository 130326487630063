import { colors } from '@config/theme'
import type { BadgeVariation } from '@stories/Theme/Badge/Badge'

export const interestToBadgeVariationMap: Record<string, string> = {
  SPORTS: 'brandRed',
  'ARTS & CULTURE': 'brandCyan',
  STEM: 'brandBlue',
  NATURE: 'brandGreen',
  FANDOM: 'brandPurple',
  ADVENTURE: 'brandYellow',
  Default: 'brandDefault',
}

export const interestToColorMap: Record<string, string> = {
  SPORTS: '#BC1B06',
  'ARTS & CULTURE': '#AB4B95',
  STEM: '#4B5AA5',
  NATURE: '#067647',
  FANDOM: '#175CD3',
  ADVENTURE: '#A15C07',
  Default: 'brandDefault',
}

export const interestToColorMapUpdated: Record<string, string> = {
  SPORTS: colors.brandRed,
  'ARTS & CULTURE': colors.brandPurple,
  STEM: colors.brandBlue,
  NATURE: '#9EAF20',
  FANDOM: colors.brandCyan,
  ADVENTURE: '#DCA701',
  Default: 'brandDefault',
}

export const getColorByInterestName = (name: string): string => {
  if (interestToColorMap[name]) {
    return interestToColorMap[name]
  }

  return interestToColorMap[Object.keys(interestToColorMap)[randomInt()]]
}

export const getBadgeVariationByInterestName = (name: string): BadgeVariation => {
  if (interestToBadgeVariationMap[name]) {
    return interestToBadgeVariationMap[name] as BadgeVariation
  }

  return interestToBadgeVariationMap[Object.keys(interestToColorMap)[randomInt()]] as BadgeVariation
}
export const getBadgeColorByIndex = (index: number): BadgeVariation => {
  return interestToBadgeVariationMap[Object.keys(interestToBadgeVariationMap)[index]] as BadgeVariation
}

const randomInt = (min: number = 0, max: number = 5): number => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}
