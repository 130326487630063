import { Icons } from '@assets/svg'
import { colors } from '@config/theme'
import { dayLength } from '@decorators/day-length'
import { dateToHumanReadable, getFirstDate, getLastDate, timeFormatter } from '@decorators/number-formatter.ts'
import { api } from '@libs/api'
import { Box, Grid, ListItemIcon, Menu, MenuItem, useMediaQuery } from '@mui/material'
import { Button } from '@stories/Theme/Button/Button'
import { Text } from '@stories/Typography/Text/Text'
import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'

import Eventbus from '../libs/eventbus.ts'
import redis from '../libs/lockr.ts'
import { Camp } from '../libs/types/Camp'
import { useAppDispatch } from '../store/hooks'
import { getUserList } from '../store/userList/userListSlice'

const FavoritesItem: FC<{ item: Camp; list_id: number | string; hideItemMenu?: boolean }> = ({
  item,
  list_id,
  hideItemMenu = false,
}) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const MenuBar = Icons['menu']
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const dispatch = useAppDispatch()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box
      sx={{
        borderLeft: `1px solid ${colors.brandGray50}`,
        borderBottom: `1px solid ${colors.brandGray50}`,
      }}
      p={3}
    >
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Text fontSize="12px">
            {item.dates[0] && (
              <>
                Start date: {dateToHumanReadable(`${getFirstDate(item.dates)}`)} <br />
              </>
            )}
            {item.dates.length > 1 && <>End date: {dateToHumanReadable(`${getLastDate(item.dates)}`)}</>}
          </Text>
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12} sm={12} md={4} order={{ sm: 2, md: 1 }}>
              <Text fontSize="14px">{dayLength(item.day_length)}</Text>
              <Text fontSize="14px">
                {`Earliest dropoff: `} {timeFormatter(`${item.earliest_dropoff_time}`)}
              </Text>
              <Text fontSize="14px">
                {`Latest pickup: `} {timeFormatter(`${item.latest_pickup_time}`)}
              </Text>
            </Grid>
            <Grid item xs={12} sm={12} md={8} order={{ sm: 1, md: 2 }}>
              <Text fontWeight="semibold" fontSize={isSmallScreen ? '18px' : '20px'}>
                <Link to={`/camps/${item.id}`}>{item.name}</Link>
              </Text>
            </Grid>
          </Grid>
        </Grid>
        {!hideItemMenu && (
          <Grid item xs={1}>
            <Button variant="secondary" onClick={handleClick} icon={<MenuBar />}></Button>
          </Grid>
        )}
      </Grid>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        disableScrollLock={true}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={async () => {
            const response = await api.deleteCampUserList(list_id as number, item.id)
            if (response.status === 200 || response.status === 201 || response.status === 204) {
              redis.rm('api:userList')

              dispatch(getUserList())
              Eventbus.trigger(Eventbus.MIXPANEL_CTA_CLICKED, {
                value: 'Removed Camp from list',
              })
              handleClose()
            }
          }}
        >
          <ListItemIcon>
            <Icons.DeleteFavoriteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default FavoritesItem
