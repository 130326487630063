import { decorateEntityIds } from '@decorators/decorateEntityIds.ts'
import { api, apiResponse } from '@libs/api'
import { Camp } from '@libs/types/Camp.ts'
import { UserList } from '@libs/types/UserList.ts'
import { call, CallEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects'

import { getCampListSelector } from '../campSearch/selectors.ts'
import { getUserList, setUserList, setUserListLoading } from './userListSlice'

function* userList(): Generator<CallEffect | PutEffect | SelectEffect, void | any> {
  yield put(setUserListLoading(true))
  const { data: userList } = (yield call(api.getUserList)) as apiResponse<UserList[]>

  const camps = (yield select(getCampListSelector)) as Camp[]
  userList.map((list) => {
    list.camps_decorated = decorateEntityIds<Camp>(list.camps, camps)
  })

  yield put(setUserList(userList))
  yield put(setUserListLoading(false))
}

export default function* userListSaga() {
  yield takeLatest(getUserList, userList)
}
