import { decorateInterestsToBadgeVariant } from '@decorators/decorateInterestsToTree'

import { Interest } from './types/Interest'

export interface BadgeInterface {
  id: number
  color: string
  name: string
}

export function getBadgesFromInterest(interestList: Interest[], interests: number[]) {
  const interestMap = decorateInterestsToBadgeVariant(structuredClone(interestList))
  const badges: BadgeInterface[] = []
  interests.forEach((id) => {
    const interest = interestMap[id]
    if (interest) {
      const color = interest.color
      const name = interest.name
      badges.push({ id, color, name })
    }
  })

  return badges
}
