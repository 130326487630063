import { setCheckedCities, setIncludeBussing } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch } from '@store/hooks.ts'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useCitiesFilters = (): void => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const filter_checked_cities = searchParams.get('filter_checked_cities')
  const includeBussingParam = searchParams.get('filter_cities_include_bussing')

  useEffect(() => {
    try {
      if (filter_checked_cities) {
        const filterCheckedCities = JSON.parse(filter_checked_cities) || []
        if (Array.isArray(filterCheckedCities)) {
          dispatch(setCheckedCities(filterCheckedCities))
          const params = new URLSearchParams(searchParams)
          params.delete('filter_checked_cities')
          const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
          window.history.replaceState(null, '', url)
        }
      }
    } catch (e) {
      console.warn('Error while setting checked cities:', e)
    }
  }, [filter_checked_cities, searchParams])

  useEffect(() => {
    try {
      if (includeBussingParam) {
        const includeBussing = includeBussingParam === 'true'
        dispatch(setIncludeBussing(includeBussing))
        const params = new URLSearchParams(searchParams)
        params.delete('filter_cities_include_bussing')
        const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
        window.history.replaceState(null, '', url)
      }
    } catch (e) {
      console.warn('Error while setting include bussing:', e)
    }
  }, [includeBussingParam, searchParams])
}
