import { colors } from '@config/theme'
import Eventbus from '@libs/eventbus'
import { SlotComponentProps } from '@mui/base'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { SliderComponentsPropsOverrides, SliderOwnerState } from '@mui/material/Slider/Slider'
import {
  setDistanceAllowBussing,
  setDistanceAllowOvernight,
  setDistanceIsZipCodeIncluded,
  setDistanceMiles,
  setDistanceZipCode,
} from '@store/campFilters/campFiltersSlice'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import React, { useEffect, useState } from 'react'

import redis from '../../../../libs/lockr.ts'

const distanceMarks = [
  {
    value: 1,
    label: '1 mile',
  },
  {
    value: 100,
    label: '100 miles',
  },
]

const valueText = (value: number): string => {
  return `$${value} `
}

const Distance = () => {
  const dispatch = useAppDispatch()

  const isInputError = (length: number): boolean => {
    return length !== 5 && length !== 0
  }

  const miles = useAppSelector((state) => state.campFilters.filters.distance.miles)
  const bussing = useAppSelector((state) => state.campFilters.filters.distance.allowBussing)
  const overnight = useAppSelector((state) => state.campFilters.filters.distance.allowOvernight)
  const zipCode = useAppSelector((state) => state.campFilters.filters.distance.zipCode)
  const zipCodeList = useAppSelector((state) => state.campSearch.zipCodeList) || []
  const isCampListLoading = useAppSelector((state) => state.campSearch.isLoading)
  const isFilteringLoading = useAppSelector((state) => state.campSearch.isFilteringLoading)

  const [isZipCodeIncluded, setIsZipCodeIncluded] = useState<boolean>(false)
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false)
  const [zipCodeState, setZipCodeState] = useState(zipCode)
  const [milesState, setMilesState] = useState<number>(miles)
  const [isZipcodeInvalid, setIsZipcodeInvalid] = useState<boolean>(isInputError(zipCode?.length ?? 0))
  const [isZipCodeComplete, setIsZipCodeComplete] = useState<boolean>(zipCode?.length === 5)

  //Get from redis 'api:v2:distances
  const distancesForZipcodeId = redis.get('api:v2:distances')

  useEffect(() => {
    Eventbus.on(Eventbus.DISTANCE_CLEAR_FILTER, handleClearAllFilters)
    return () => Eventbus.off(Eventbus.DISTANCE_CLEAR_FILTER, handleClearAllFilters)
  }, [])

  useEffect(() => {
    setIsZipCodeIncluded(zipCodeList.includes(zipCode))
    setIsZipCodeComplete(zipCode.length === 5)
    setZipCodeState(zipCode)
  }, [zipCode, zipCodeList])

  useEffect(() => {
    if (!isCampListLoading && !isZipCodeIncluded && zipCode.length === 4) {
      handleClearAllFilters()
    }
  }, [isCampListLoading])
  const handleClearAllFilters = () => {
    setZipCodeState('')
    setIsZipcodeInvalid(false)
    setIsZipCodeComplete(false)
    setIsInputDisabled(false)
    setMilesState(100)
  }

  function handleChange(_event: Event, value: number | number[]) {
    setMilesState(value as number)
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const handleDistanceChange = (_event: React.SyntheticEvent | Event, newValue: number | number[]): void => {
    dispatch(setDistanceMiles(newValue as number))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Distance Threshold Changed',
      value: newValue,
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const handleOvernightChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setDistanceAllowOvernight(event.target.checked))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Distance Ignore Overnight Changed',
      value: event.target.checked ? 'on' : 'off',
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const handleBussingChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setDistanceAllowBussing(event.target.checked))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Distance Include Bussing Changed',
      value: event.target.checked ? 'on' : 'off',
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.replace(/[^0-9]/g, '')

    const isValueComplete = value.length === 5
    const isPreviousStateComplete = zipCode.length === 5 && value.length <= 4
    const isZipCodeIncluded = isValueComplete && zipCodeList.includes(value)
    const isInvalid = isInputError(value.length)

    // Update states
    setZipCodeState(value)
    setIsInputDisabled(isValueComplete)
    setIsZipCodeComplete(isValueComplete)
    setIsZipCodeIncluded(isZipCodeIncluded)
    setIsZipcodeInvalid(isInvalid)

    // If the zip code is valid or was previously complete, trigger further actions
    if (isZipCodeIncluded || isPreviousStateComplete) {
      dispatch(setDistanceIsZipCodeIncluded(isZipCodeIncluded))
      dispatch(setDistanceZipCode(value))
      Eventbus.trigger(Eventbus.RESET_PAGING)
    }

    if (isZipCodeIncluded) {
      Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
        filter: 'Correct Zipcode Entered',
        value: value,
      })
    }

    // Delay re-enabling input to prevent rapid state changes
    setTimeout(() => {
      setIsInputDisabled(false)
    }, 1000)
  }

  const getErrorMessageForZipCodeFilter = (): string => {
    // If input is disabled or list is loading, no error message is needed
    if (isInputDisabled || isCampListLoading || isFilteringLoading) {
      return ''
    }

    // Handle invalid or incomplete zip codes
    if (isZipcodeInvalid && !isZipCodeComplete) {
      return 'Please enter a 5-digit zipcode'
    }

    // If zip code is complete, check further conditions
    if (isZipCodeComplete) {
      if (!isZipCodeIncluded) {
        return 'Sorry, your ZIP code was not found for this metro area'
      }
      // If distances are undefined, show an error message
      if (distancesForZipcodeId === undefined) {
        return 'Sorry, we weren’t able to calculate distances. Please try again'
      }
    }

    // No errors, return an empty string
    return ''
  }

  return (
    <>
      <FormControl variant="standard" sx={{ pt: 3, mb: 0.5 }} fullWidth>
        <InputLabel sx={{ mt: -3 }} htmlFor="distance-field">
          <Typography variant="body2" component={'span'}>
            Your location
          </Typography>
        </InputLabel>
        <TextField
          error={isZipcodeInvalid}
          InputProps={{
            endAdornment: isZipcodeInvalid ? (
              <InputAdornment position={'end'}>
                <ErrorOutlineIcon color={'error'} />
              </InputAdornment>
            ) : (
              (isInputDisabled || isFilteringLoading) && (
                <InputAdornment position={'end'}>
                  <CircularProgress size={12} />
                </InputAdornment>
              )
            ),
          }}
          size="small"
          placeholder="Enter a zipcode to calculate distance"
          id="distance-field"
          variant="outlined"
          inputProps={{ maxLength: 5, 'data-testid': 'zipcode-input' }}
          disabled={isInputDisabled || isCampListLoading}
          onChange={handleZipCodeChange}
          value={zipCodeState}
        />

        <Typography
          mt={0.5}
          color={colors.brandError500}
          fontSize={'14px'}
          data-testid="error-message-zipcode-input"
          sx={{ minHeight: '42px' }}
        >
          {getErrorMessageForZipCodeFilter()}
        </Typography>
      </FormControl>
      <Box>
        <Typography variant="body1" fontWeight={600} mb={2}>
          Distance (miles)
        </Typography>
        <Box pt={4} px={3} mb={2}>
          <Slider
            aria-controls="slider-distance-container"
            disabled={!isZipCodeComplete || !isZipCodeIncluded}
            slotProps={{
              input: {
                'data-testid': 'slider-distance',
              } as SlotComponentProps<'input', SliderComponentsPropsOverrides, SliderOwnerState>,
            }}
            getAriaValueText={valueText}
            value={milesState}
            onChange={handleChange}
            valueLabelDisplay="on"
            onChangeCommitted={handleDistanceChange}
            marks={distanceMarks}
            min={1}
            max={100}
            step={1}
          />
        </Box>
        <Box>
          <FormControlLabel
            sx={{ mb: 2 }}
            control={
              <Switch
                data-testid="bussing-switch"
                onChange={handleBussingChange}
                sx={{ m: 1 }}
                checked={bussing}
                disabled={!isZipCodeComplete || !isZipCodeIncluded}
              />
            }
            label="Include camps with bussing locations within distance"
          />
          <FormControlLabel
            control={
              <Switch
                data-testid="overnight-switch"
                onChange={handleOvernightChange}
                sx={{ m: 1 }}
                checked={overnight}
                disabled={!isZipCodeComplete || !isZipCodeIncluded}
              />
            }
            label="Ignore distance for overnight camps"
          />
        </Box>
      </Box>
    </>
  )
}

export default Distance
