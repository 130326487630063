export const getMonthFromStartDate = (date_start: string): string => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const monthIndex = getMonth(date_start) - 1

  return monthNames[monthIndex]
}

export const getDatesFromStartDateAndEndDate = (date_start: string, date_end: string): string => {
  const startDay = getDay(date_start)
  const endDay = getDay(date_end)
  if (startDay === endDay) {
    return startDay.toString()
  }
  return `${startDay}-${endDay}`
}

export const getMonthsFromStartDateAndEndDate = (date_start: string, date_end: string): string => {
  const startMonth = getMonthFromStartDate(date_start)
  const endMonth = getMonthFromStartDate(date_end)
  if (startMonth === endMonth) {
    return startMonth.toString()
  }
  return `${startMonth.slice(0, 3)} ${endMonth.slice(0, 3)}`
}
export const getArrayMonthsFromStartDateEndDate = (date_start: string, date_end: string): string[] => {
  return getMonthsFromStartDateAndEndDate(date_start, date_end).split(' ')
}
export const getCurrentDate = (): string => {
  const today: Date = new Date()
  const year: number = today.getFullYear()
  const month: number = today.getMonth() + 1
  const day: number = today.getDate()

  const formattedMonth: string = month < 10 ? `0${month}` : `${month}`
  const formattedDay: string = day < 10 ? `0${day}` : `${day}`

  return `${year}-${formattedMonth}-${formattedDay}`
}

const getMonth = (date: string): number => {
  const dateParts = date.split('-')
  return parseInt(dateParts[1])
}

const getDay = (date: string): number => {
  const dateParts = date.split('-')
  return parseInt(dateParts[2])
}
